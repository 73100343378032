import { Box } from '@mui/material'
import { BreadcrumbBar, LayoutWithSideRail, PhoenixBaseCard } from 'componix'
import { useParams } from 'react-router-dom'
import { useGetCarrierGroupCarrierList, useGetCarrierGroupInfo } from '../cache/carrierCache'
import CarrierGroupCarriersTable from '../components/Carrier/CarrierGroupCarriersTable'
import CarrierGroupGutterCard from '../components/Carrier/CarrierGroupLeftGutterCard'
import CarrierGroupNameHistoryCard from '../components/Carrier/CarrierGroupNameHistory/CarrierGroupNameHistoryCard'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

const CarrierGroup = () => {
  const { pageStyle } = useNavbar()
  const { groupGuid } = useParams()

  const {
    data: carrierGroupInfo,
    isPending: isCarrierGroupInfoLoading,
    isError: isCarrierGroupInfoError,
  } = useGetCarrierGroupInfo(groupGuid as string)

  const {
    data: carrierList,
    isPending: isCarrierListLoading,
    isError: isCarrierListError,
  } = useGetCarrierGroupCarrierList(groupGuid as string)

  useSetAlertDetails([isCarrierGroupInfoError, isCarrierListError])

  return (
    <Box style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Carriers`,
            to: `/carriers`,
          },
          {
            label: `Carrier Group ${carrierGroupInfo?.groupID}`,
            to: `/carriers/group/${groupGuid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <PhoenixBaseCard cardTitle={'Carriers'}>
              <CarrierGroupCarriersTable data={carrierList ?? []} isLoading={isCarrierListLoading} />
            </PhoenixBaseCard>
            <CarrierGroupNameHistoryCard carrierGroupGuid={groupGuid as string} />
          </>
        }
        sideRailComponentArray={[
          <CarrierGroupGutterCard key="cgi" carrierGroup={carrierGroupInfo} isLoading={isCarrierGroupInfoLoading} />,
        ]}
      />
    </Box>
  )
}

export default CarrierGroup
