import { MRT_ColumnDef } from 'material-react-table'
import { RateSplitPointResponse } from '../../../../models/Rate/RateSplitPointResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

const RateSplitPointsTableColumns: MRT_ColumnDef<RateSplitPointResponse>[] = [
  {
    accessorKey: 'splitPoint',
    header: 'Split Point',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'lossStart',
    header: 'Loss Start',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'lossEnd',
    header: 'Loss End',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 800,
  },
]

export default RateSplitPointsTableColumns
