import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FieldGroup } from 'componix'
import { Controller } from 'react-hook-form'
import { ControllingCoverageItem } from '../../models/ControllingCoverageOptions'
import { generateControllingCoverageLabel } from '../../utils/coverage/generateControllingCoverageLabel'

interface ControllingCoverageSelectProps {
  activeStep: number
  fieldName: string
  options: ControllingCoverageItem[]
}

const ControllingCoverageSelect = ({ activeStep, fieldName, options }: ControllingCoverageSelectProps) => {
  const label = 'Controlling coverage'
  const moreThanOneOptionAvailable = options?.length > 1
  const noOptionsAvailable = options?.length === 0 || !options

  return (
    <Controller
      name={fieldName}
      render={({ field }) => {
        const currentOption = options?.find((option) => String(option?.coverageId) === String(field?.value))
        const showMultiSelect = (activeStep === 1 && moreThanOneOptionAvailable) || noOptionsAvailable
        return showMultiSelect ? (
          <FormControl fullWidth>
            <InputLabel size={'small'}>Controlling coverage</InputLabel>
            <Select {...field} label={label} type={'select'} fullWidth size={'small'}>
              {noOptionsAvailable && <MenuItem value={''}>No options available</MenuItem>}
              {options?.map((option) => (
                <MenuItem value={option.coverageId} key={option.coverageId}>
                  {generateControllingCoverageLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FieldGroup label={label} value={generateControllingCoverageLabel(currentOption)} />
        )
      }}
    />
  )
}

export default ControllingCoverageSelect
