import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { RatePeriodResponse } from '../../models/Rate/RatePeriodResponse'
import { tableCellFloat } from '../../utils/table/tableCellFloat'

const columns: MRT_ColumnDef<RatePeriodResponse>[] = [
  {
    header: 'Rate Effective Date',
    accessorKey: 'ratingPeriodEffectiveDate',
    Cell: (table) => (
      <PhoenixLink
        to={`/rates/${table.row.original.ratingPeriodId}`}
        value={table.row.original.ratingPeriodEffectiveDate}
      />
    ),
    size: 130,
  },
  {
    header: 'Rate Expiration Date',
    accessorKey: 'ratingPeriodExpirationDate',
    size: 155,
  },
  {
    header: 'Employer Limit',
    Header: () => (
      <div>
        Employer
        <br />
        Limit
      </div>
    ),
    accessorKey: 'employerLimit',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 75,
  },
  {
    header: 'Off Load Factor (Exr)',
    Header: () => (
      <div>
        Off Load
        <br />
        Factor (Exr)
      </div>
    ),
    accessorKey: 'offLoadFactorExperienceRating',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 2),
    size: 90,
  },
  {
    header: 'Off Load Factor (Pol)',
    Header: () => (
      <div>
        Off Load
        <br />
        Factor (Pol)
      </div>
    ),
    accessorKey: 'offLoadFactorPolicyPremium',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 2),
    size: 90,
  },
  {
    header: 'Expense Constant',
    Header: () => (
      <div>
        Expense
        <br />
        Constant
      </div>
    ),
    accessorKey: 'expenseConstant',
    size: 75,
  },
  {
    header: 'Rate Multiplier',
    Header: () => (
      <div>
        Rate
        <br />
        Multiplier
      </div>
    ),
    accessorKey: 'rateMultiplier',
    size: 75,
  },
  {
    header: 'General Factor',
    Header: () => (
      <div>
        General
        <br />
        Factor
      </div>
    ),
    accessorKey: 'generalFactor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 2),
    size: 75,
  },
  {
    header: 'USL&H Accident Limit',
    accessorKey: 'usLongshoreAndHarborAccidentLimit',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
  },
]

interface RatesTableProps {
  data: RatePeriodResponse[]
  isLoading: boolean
}

const RatesTable = ({ data, isLoading }: RatesTableProps) => {
  return <PhoenixBaseTable columns={columns} data={data} isLoading={isLoading} searchable />
}

export default RatesTable
