import { PeopleAlt } from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { useGetClassSummaryInfo } from '../../cache/classCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'

interface ClassSummaryCardProps {
  classId: string | undefined
}

const ClassSummaryCard = ({ classId }: ClassSummaryCardProps) => {
  const { data: classSummary, isPending, isError } = useGetClassSummaryInfo(classId as string)

  useSetAlertDetails([isError])
  return (
    <PhoenixBaseCard cardTitle="Class Summary" contentPadded={true}>
      <Box sx={{ padding: '16px', width: '100%' }}>
        <FieldGroup label="Class Code" icon={<PeopleAlt />} value={classSummary?.classCode} isLoading={isPending} />
        <FieldGroup label="Phraseology" value={classSummary?.classDescription} isLoading={isPending} />

        <FieldGroup
          label="Effective Date"
          value={classSummary?.classEffectiveDate}
          icon={<CalendarTodayIcon />}
          isLoading={isPending}
        />
        <FieldGroup
          label="Expiration Date"
          value={classSummary?.classExpirationDate}
          icon={<CalendarTodayIcon />}
          isLoading={isPending}
        />
        <FieldGroup
          label="Modified Date"
          value={classSummary?.classModifiedDate}
          icon={<CalendarTodayIcon />}
          isLoading={isPending}
        />
      </Box>
    </PhoenixBaseCard>
  )
}

export default ClassSummaryCard
