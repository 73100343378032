import { Box } from '@mui/material'
import { BreadcrumbBar, LayoutWithSideRail, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetClassLossCost, useGetClassSplitPointDateList } from '../cache/classCache'
import ClassDetailsCard from '../components/Class/ClassDetailsCard'
import ClassLossCostTable from '../components/Class/ClassLossCostTable'
import ClassSplitPointAndRatioCard from '../components/Class/ClassSplitPointAndRatioCard'
import ClassSummaryCard from '../components/Class/ClassSummaryCard'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const ClassDetails = () => {
  const { pageStyle } = useNavbar()
  const { classId } = useParams()

  const { data: ancestorData } = useGetAncestors(classId as UUID, HierarchyTypes.CLASS)
  const { data: lossCostData = [], isPending: isCLCpending, lazyFetch: CLCLazyFetch } = useGetClassLossCost(classId)
  const {
    data: dateList = [],
    isPending: isDateListpending,
    isError,
  } = useGetClassSplitPointDateList(classId as string)

  useSetAlertDetails([isError])
  return (
    <Box style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Class Codes`,
            to: `/classes`,
          },
          {
            label: `Class Code ${ancestorData?.class?.identifier}`,
            to: `/classes/${classId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <ClassDetailsCard classId={classId} />
            <PhoenixBaseCard cardTitle="Loss Cost" onExpand={CLCLazyFetch} collapsible>
              <ClassLossCostTable data={lossCostData} isLoading={isCLCpending} />
            </PhoenixBaseCard>
            <ClassSplitPointAndRatioCard
              classId={classId}
              rateDateList={dateList}
              dateListLoading={isDateListpending}
            />
          </>
        }
        sideRailComponentArray={[<ClassSummaryCard key="csc" classId={classId} />]}
      />
    </Box>
  )
}

export default ClassDetails
