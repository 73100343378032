import { PhoenixBaseCard } from 'componix'
import { useGetRateSplitPoints } from '../../../cache/rateCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { DatePeriodResponse } from '../../../models/DatePeriodResponse'
import RateSplitPointsTable from './RateSplitPointsTable'

interface RateSplitPointsCardProps {
  ratePeriod: DatePeriodResponse | undefined
}

export const RateSplitPointsCard = ({ ratePeriod }: RateSplitPointsCardProps) => {
  const { data = [], isPending, isError } = useGetRateSplitPoints(ratePeriod as DatePeriodResponse)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Split Points'} collapsible={false}>
      <RateSplitPointsTable data={data} isLoading={isPending} />
    </PhoenixBaseCard>
  )
}

export default RateSplitPointsCard
