import { MRT_ColumnDef } from 'material-react-table'
import { ClassSplitPointAndDRatioResponse } from '../../../../models/Class/ClassSplitPointAndDRatioResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

const ClassSplitPointAndRatioTableColumns: MRT_ColumnDef<ClassSplitPointAndDRatioResponse>[] = [
  {
    accessorKey: 'splitPoint',
    header: 'Split Point',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'lossStart',
    header: 'Loss Start',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'lossEnd',
    header: 'Loss End',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'discountRatio',
    header: 'D-Ratio',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 3),
    size: 800,
  },
]

export default ClassSplitPointAndRatioTableColumns
