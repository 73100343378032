import { Box, Chip } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import { ClassAttribute } from '../../../models/Class/ClassDetails'

interface ClassDetailsAttributesCardProps {
  attributeGroup: string
  attributes: ClassAttribute[] | undefined
}

const ClassDetailsAttributesCard = ({ attributeGroup, attributes }: ClassDetailsAttributesCardProps) => {
  return (
    <PhoenixBaseCard cardTitle={attributeGroup} variantType="Secondary" collapsible={true}>
      <Box sx={{ pt: 1.5 }}>
        {attributes?.map((attribute, index) => (
          <Chip
            sx={{ mr: 1, mb: 1 }}
            key={`cac-${attributeGroup}-${index}`}
            label={attribute?.attribute}
            disabled={!attribute?.enabled}
          />
        ))}
      </Box>
    </PhoenixBaseCard>
  )
}

export default ClassDetailsAttributesCard
