import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { RatePeriodResponse } from '../../models/Rate/RatePeriodResponse'
import { tableCellFloat } from '../../utils/table/tableCellFloat'

interface RateCardProps {
  rate: RatePeriodResponse | undefined
  loading: boolean
}

const RateDataCard = ({ rate, loading }: RateCardProps) => {
  return (
    <PhoenixBaseCard cardTitle="Rate Data" contentPadded={true} isLoading={loading}>
      <Box sx={{ padding: '16px', width: '100%' }}>
        <FieldGroup
          label="Rate Effective Date"
          value={rate?.ratingPeriodEffectiveDate}
          icon={<CalendarTodayIcon />}
          isLoading={loading}
        />
        <FieldGroup
          label="Rate Expiration Date"
          value={rate?.ratingPeriodExpirationDate}
          icon={<CalendarTodayIcon />}
          isLoading={loading}
        />
        <FieldGroup label="Employer Limit" value={tableCellFloat(rate?.employerLimit, 0)} isLoading={loading} />
        <FieldGroup
          label="Off Load Factor (Exr)"
          value={rate?.offLoadFactorExperienceRating?.toFixed(2)}
          isLoading={loading}
        />
        <FieldGroup
          label="Off Load Factor (Pol)"
          value={rate?.offLoadFactorPolicyPremium?.toFixed(2)}
          isLoading={loading}
        />
        <FieldGroup label="Expense Constant" value={rate?.expenseConstant} isLoading={loading} />
        <FieldGroup label="Rate Multiplier" value={rate?.rateMultiplier} isLoading={loading} />
        <FieldGroup label="General Factor" value={rate?.generalFactor?.toFixed(2)} isLoading={loading} />
        <FieldGroup
          label="USL&H Accident Limit"
          value={tableCellFloat(rate?.usLongshoreAndHarborAccidentLimit, 0)}
          isLoading={loading}
        />
      </Box>
    </PhoenixBaseCard>
  )
}

export default RateDataCard
