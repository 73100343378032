import { ClassDetails } from '../models/Class/ClassDetails'
import { ClassLossCostResponse } from '../models/Class/ClassLossCost'
import { ClassSplitPointAndDRatioResponse } from '../models/Class/ClassSplitPointAndDRatioResponse'
import { ClassSummaryResponse } from '../models/Class/ClassSummaryResponse'
import { DatePeriodResponse } from '../models/DatePeriodResponse'
import api from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

const getClassList = async (): Promise<ClassSummaryResponse[]> => {
  const response = await api.get(Endpoints.Classes)
  return response.data
}

const getClassSummaryInfo = async (classId: string): Promise<ClassSummaryResponse> => {
  const response = await api.get(`${Endpoints.Classes}/${classId}/info`)
  return response.data
}

const getClassLossCost = async (classId: string): Promise<ClassLossCostResponse[]> => {
  const response = await api.get(`${Endpoints.Classes}/${classId}/lossCost`)
  return response.data
}

const getClassDetails = async (classId: string): Promise<ClassDetails> => {
  const response = await api.get(`${Endpoints.Classes}/${classId}/details`)
  return response.data
}

const getClassSplitPointDateList = async (classId: string): Promise<DatePeriodResponse[]> => {
  const response = await api.get(`${Endpoints.Rates}/dRatioPeriodsByClass`, { params: { classId } })
  return response.data
}

const getClassSplitPointsAndDRatios = async (
  classId: string,
  rateDate: DatePeriodResponse
): Promise<ClassSplitPointAndDRatioResponse[]> => {
  const response = await api.get(`${Endpoints.Rates}/rateSplitPointsAndDiscountRatiosByClass`, {
    params: { classId, effectiveDate: rateDate.effectiveDate, expirationDate: rateDate.expirationDate },
  })
  return response.data
}
export {
  getClassList,
  getClassSummaryInfo,
  getClassLossCost,
  getClassDetails,
  getClassSplitPointDateList,
  getClassSplitPointsAndDRatios,
}
