import { MRT_ColumnDef } from 'material-react-table'
import { RatePremiumDiscountResponse } from '../../../../models/Rate/RatePremiumDiscountResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

export const RatePremiumDiscountTableColumns: MRT_ColumnDef<RatePremiumDiscountResponse>[] = [
  {
    accessorKey: 'layerNumber',
    header: 'Layer',
    size: 40,
  },
  {
    accessorKey: 'layerAmount',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    header: 'Layer Amount',
    size: 40,
  },
  {
    accessorKey: 'stockFactor',
    header: 'Stock Factor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 1),
    size: 40,
  },
  {
    accessorKey: 'nonStockFactor',
    header: 'Non Stock Factor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 1),
    size: 800,
  },
]

export default RatePremiumDiscountTableColumns
