import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink } from 'componix'
import { useMemo } from 'react'
import { useGetClassDetails } from '../../../cache/classCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import ClassDetailsAttributesCard from './ClassDetailsAttributesCard'
import { ClassAttributeEnum, getGroupedClassAttributes } from './ClassDetailsCard.utils'

interface ClassDetailsCardProps {
  classId: string | undefined
}

const ClassDetailsCard = ({ classId }: ClassDetailsCardProps) => {
  const { data, isError, isPending } = useGetClassDetails(classId as string)
  useSetAlertDetails([isError])
  const attributeSet = useMemo(() => {
    return getGroupedClassAttributes(data)
  }, [data])

  return (
    <PhoenixBaseCard cardTitle={'Class Details'}>
      <Grid container sx={{ px: 3, py: 2 }} spacing={1}>
        <Grid item xs={6} sm={3}>
          <FieldGroup label={'Hazard Code'} value={data?.hazardGroup} isLoading={isPending} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <FieldGroup
            label={'Substitute Classes'}
            value={
              data?.substituteClasses.length
                ? data.substituteClasses.map((subClass, i) => (
                    <span key={subClass.classID}>
                      <PhoenixLink to={`/classes/${subClass.classID}`} value={subClass.classCode} />
                      {i < data?.substituteClasses.length - 1 && ', '}
                    </span>
                  ))
                : null
            }
            isLoading={isPending}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <FieldGroup label={'Governing Class Priority'} value={data?.governingClassPriority} isLoading={isPending} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <FieldGroup label={'Industry Group'} value={data?.industryGroup} isLoading={isPending} />
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2 }} spacing={1}>
        <Grid item xs={12} sm={6}>
          <ClassDetailsAttributesCard
            attributeGroup={ClassAttributeEnum.PREMIUM_ALGORITHM}
            attributes={attributeSet?.[ClassAttributeEnum.PREMIUM_ALGORITHM]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ClassDetailsAttributesCard
            attributeGroup={ClassAttributeEnum.ELIGIBILITY}
            attributes={attributeSet?.[ClassAttributeEnum.ELIGIBILITY]}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pt: 1, pb: 2 }} spacing={1}>
        <Grid item xs={12} sm={6}>
          <ClassDetailsAttributesCard
            attributeGroup={ClassAttributeEnum.CLASS_TYPE}
            attributes={attributeSet?.[ClassAttributeEnum.CLASS_TYPE]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ClassDetailsAttributesCard
            attributeGroup={ClassAttributeEnum.OTHER_TYPE}
            attributes={attributeSet?.[ClassAttributeEnum.OTHER_TYPE]}
          />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}

export default ClassDetailsCard
