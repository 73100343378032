import { Box, TextField } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useFindMatchingCoverage } from '../../../cache/workQueueCache'
import { MatchingCoverageResponse } from '../../../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../../../models/WorkItemDetails/SuggestedMatchModel'
import FindMatchButtonGroup, { StageOfFindMatchGroupButtonEnum } from './FindMatchButtonGroup'

interface IFormInput {
  coverageID: string
}

interface FoundRecordContentProps {
  mapResponseToMatch: (response: MatchingCoverageResponse | null) => SuggestedMatchModel
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  backToCoverage: () => void
  hasSelectedPolices: boolean
}

const FindMatchCoverageForm = ({
  mapResponseToMatch,
  setFoundMatch,
  backToCoverage,
  hasSelectedPolices,
}: FoundRecordContentProps) => {
  const { control, handleSubmit, register, watch, setError } = useForm({
    mode: 'all',
    defaultValues: {
      coverageID: '',
    },
  })

  const formCoverageId = watch('coverageID')

  const coverageIdValidation = {
    maxLength: {
      message: 'Coverage ID must not contain more than 7 characters.',
      value: 7,
    },
    required: {
      message: 'Please enter a value.',
      value: true,
    },
    pattern: {
      message: 'Non numeric characters are not allowed.',
      value: /^\d*$/,
    },
  }

  const {
    refetch: fetchMatchingCoverage,
    isRefetching: isFetchingMatchingCoverage,
    isLoading: isLoadingMatchingCoverage,
  } = useFindMatchingCoverage(formCoverageId)

  const onSubmitFindMatchingCoverage: SubmitHandler<IFormInput> = async () => {
    const result = await fetchMatchingCoverage()
    if (result.data) {
      const match = mapResponseToMatch(result.data)
      setFoundMatch(match)
    } else {
      setError('coverageID', { message: 'No records to display.' })
    }
  }

  return (
    <Box display="block" width={'100%'}>
      <form onSubmit={handleSubmit(onSubmitFindMatchingCoverage)}>
        <Box my={2} sx={{ width: '50%' }}>
          <Controller
            name="coverageID"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...register('coverageID', coverageIdValidation)}
                inputProps={{ type: 'search' }}
                fullWidth
                label="Coverage ID"
                size={'small'}
                variant={'outlined'}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <FindMatchButtonGroup
          currentStage={StageOfFindMatchGroupButtonEnum.SELECT}
          backToCoverage={backToCoverage}
          isNextDisable={isFetchingMatchingCoverage || isLoadingMatchingCoverage || !hasSelectedPolices}
          isNextLoading={isFetchingMatchingCoverage || isLoadingMatchingCoverage}
        />
      </form>
    </Box>
  )
}

export default FindMatchCoverageForm
