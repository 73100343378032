import { Box } from '@mui/material'
import { NoResultsFound, PhoenixBaseCard } from 'componix'
import { useState } from 'react'
import { useGetClassSplitPointsAndDRatios } from '../../../cache/classCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { DatePeriodResponse } from '../../../models/DatePeriodResponse'
import SearchDropdown, { Option } from '../../SearchDropdown/SearchDropdown'
import ClassSplitPointAndRatioTable from './ClassSplitPointAndRatioTable'

interface ClassSplitPointAndRatioCardProps {
  classId: string | undefined
  rateDateList: DatePeriodResponse[]
  dateListLoading: boolean
}

const ClassSplitPointAndRatioCard = ({ classId, rateDateList, dateListLoading }: ClassSplitPointAndRatioCardProps) => {
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [selectedDateOption, setSelectedDateOption] = useState<DatePeriodResponse>()
  const [hasCardExpendedBefore, setHasCardExpendedBefore] = useState<boolean>(false)
  const rateDateOptions = rateDateList.map(
    (rd) =>
      ({
        id: rd.effectiveDate,
        value: rd.effectiveDate,
        label: rd.effectiveDate,
      }) as Option
  )

  const {
    data = [],
    isLoading,
    isError,
    lazyFetch,
  } = useGetClassSplitPointsAndDRatios(classId as string, selectedDateOption as DatePeriodResponse)
  useSetAlertDetails([isError])

  const handleRateDateSelect = (event: { target: { value: string } }) => {
    setSelectedDate(event.target.value)
    setSelectedDateOption(rateDateList.find((x) => x.effectiveDate === event.target.value))
    lazyFetch()
  }

  const setRateEffectiveDate = () => {
    setHasCardExpendedBefore(true)
    if (!rateDateList?.length) return

    setSelectedDate(rateDateList[0].effectiveDate as string)
    setSelectedDateOption(rateDateList[0])
    lazyFetch()
  }

  const shouldRateDateDropdownRender = () => {
    return hasCardExpendedBefore || (!isLoading && !hasCardExpendedBefore)
  }

  return (
    <PhoenixBaseCard
      cardTitle={'Split Points and D Ratios'}
      collapsible={true}
      onExpand={setRateEffectiveDate}
      isLoading={dateListLoading}
    >
      {!rateDateList?.length ? (
        <NoResultsFound />
      ) : (
        <>
          {shouldRateDateDropdownRender() && (
            <Box
              sx={{
                margin: shouldRateDateDropdownRender() && !isLoading ? '12px auto -50px 12px' : '12px auto 0 12px',
                minWidth: '200px',
                zIndex: 1000,
              }}
            >
              <SearchDropdown
                handleSelect={handleRateDateSelect}
                labelText={'Rate Effective Date'}
                value={selectedDate}
                data-testid="ClassRateDateDropDown"
                error={false}
                helperText={null}
                options={rateDateOptions}
                size={'small'}
              />
            </Box>
          )}
          <ClassSplitPointAndRatioTable data={data} isLoading={isLoading} />
        </>
      )}
    </PhoenixBaseCard>
  )
}

export default ClassSplitPointAndRatioCard
