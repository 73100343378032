import { Close, Search } from '@mui/icons-material'
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { NoResultsFound, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { useState } from 'react'
import { useGetRateWeightsAndBallasts } from '../../../cache/rateCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { DatePeriodResponse } from '../../../models/DatePeriodResponse'
import { RateBallast, RateWeight } from '../../../models/Rate/RateWeightsAndBallastsResponse'
import RateBallastsTableColumns from './WeightsAndBallastsTable/RateBallastsTableColumns'
import RateWeightsTableColumns from './WeightsAndBallastsTable/RateWeightsTableColumns'

interface RateWeightsAndBallastsCardProps {
  ratePeriod: DatePeriodResponse | undefined
}

const RateWeightsAndBallastsCard = ({ ratePeriod }: RateWeightsAndBallastsCardProps) => {
  const [touched, setTouched] = useState(false)
  const [filterLossInput, setFilterLossInput] = useState<string>('')

  const { data, isLoading, isError, lazyFetch } = useGetRateWeightsAndBallasts(ratePeriod as DatePeriodResponse)
  useSetAlertDetails([isError])
  const filterTableData = <T extends RateWeight | RateBallast>(tableData: T[] | undefined, lossInput: string): T[] => {
    if (!tableData) return []
    return lossInput
      ? tableData.filter((data) => (data.lossStart ?? 0) <= +lossInput && +lossInput <= (data.lossEnd ?? Math.max()))
      : tableData
  }
  const rateWeights = filterTableData(data?.rateWeights, filterLossInput)
  const rateBallasts = filterTableData(data?.rateBallasts, filterLossInput)

  const handleFilterLossInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setTouched(!event.target.value)
    }
    setFilterLossInput(event.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent additional non number characters
    if (['.', ',', 'e', 'E', '-'].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <PhoenixBaseCard cardTitle={'Weights and Ballasts'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      {!data?.rateBallasts?.length && !data?.rateWeights?.length ? (
        <NoResultsFound message="Not applicable for this rate period." />
      ) : (
        <>
          <TextField
            size="small"
            type="number"
            variant="outlined"
            value={filterLossInput}
            onChange={handleFilterLossInput}
            onKeyDown={handleKeyDown}
            onFocus={() => setTouched(true)}
            onBlur={(e) => setTouched(!!e.target.value)}
            placeholder={!touched ? 'Expected Losses' : ''}
            label={!touched ? '' : 'Expected Losses'}
            InputLabelProps={{
              shrink: !!filterLossInput || touched,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: filterLossInput ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    id="ClearSearchValue-button"
                    aria-label="Clear Search Value"
                    onClick={() => {
                      setFilterLossInput('')
                      setTouched(false)
                    }}
                    edge="end"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
            sx={{
              '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                display: 'none',
              },
              m: 1,
              margin: '12px auto 6px 18px',
              minWidth: '200px',
            }}
          />
          <Grid container sx={{ px: 2 }} spacing={3}>
            <Grid item xs={6}>
              <PhoenixBaseCard cardTitle={'Weights'} variantType="Secondary" collapsible={false}>
                <PhoenixBaseTable columns={RateWeightsTableColumns} data={rateWeights} />
              </PhoenixBaseCard>
            </Grid>
            <Grid item xs={6}>
              <PhoenixBaseCard cardTitle={'Ballasts'} variantType="Secondary" collapsible={false}>
                <PhoenixBaseTable columns={RateBallastsTableColumns} data={rateBallasts} />
              </PhoenixBaseCard>
            </Grid>
          </Grid>
        </>
      )}
    </PhoenixBaseCard>
  )
}

export default RateWeightsAndBallastsCard
