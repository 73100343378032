import { BreadcrumbBar, LayoutWithSideRail } from 'componix'
import { useParams } from 'react-router-dom'
import { useGetUnmatchedTransaction, useGetWorkItemDetails } from '../cache/workQueueCache'
import SuggestedMatches from '../components/WorkItemDetails/SuggestedMatches'
import UnmatchedTransaction from '../components/WorkItemDetails/UnmatchedTransaction'
import WorkItemDetailsCard from '../components/WorkItemDetails/WorkItemDetailsCard'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

export const UnmatchedPolicies = () => {
  const { workItemId } = useParams()
  const { pageStyle } = useNavbar()

  const {
    data: workItemDetails,
    isPending: isWorkItemDetailsLoading,
    isError: isWorkItemDetailsError,
  } = useGetWorkItemDetails(workItemId as string)

  const {
    data: unmatchedTransaction,
    isPending: isUnmatchedTransactionLoading,
    isError: isUnmatchedTransactionError,
  } = useGetUnmatchedTransaction(workItemId as string)

  const isDataLoading = isWorkItemDetailsLoading || isUnmatchedTransactionLoading

  useSetAlertDetails([isWorkItemDetailsError, isUnmatchedTransactionError])

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Work Queue`,
            to: `/workqueue`,
          },
          {
            label: `Work Item ${workItemDetails?.workItemNumber}`,
            to: `/workqueue/unmatched-policies/${workItemId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <UnmatchedTransaction unmatchedTransaction={unmatchedTransaction} isLoading={isDataLoading} />
            <SuggestedMatches
              unmatchedTransaction={unmatchedTransaction}
              uuid={workItemId as string}
              workItemStatus={workItemDetails?.workItemStatus}
            />
          </>
        }
        sideRailComponentArray={[<WorkItemDetailsCard key={'wid'} data={workItemDetails} isLoading={isDataLoading} />]}
      />
    </div>
  )
}

export default UnmatchedPolicies
