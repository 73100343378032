import { MRT_ColumnDef } from 'material-react-table'

/**
 * Generates a list of MRT columns from a json json object.
 * Optionally allows consumer to transform a camel case header into sentence case
 * @param details generic json object from which to extract column headers
 * @param uncamelCase optional: assumption is that data is coming directly from the DB in CamelCase, so setting this true will render it in sentence case
 * @returns MRT_ColumnDef<object>[]
 */
export const generateColumns = (details: object, transformCamelCase: boolean = false) => {
  let columns: MRT_ColumnDef<object>[] = []
  const keys = Object.keys(details)
  if (keys.length == 0) {
    return []
  }
  columns = keys.map((key) => {
    const header = transformCamelCase ? key.replace(/([A-Z])/g, ' $1').replace(/([_])/g, ' ') : key
    return {
      accessorKey: key,
      header: header,
    }
  })
  return columns
}
