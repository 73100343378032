import { ClassAttribute, ClassDetails } from '../../../models/Class/ClassDetails'

export enum ClassAttributeEnum {
  PREMIUM_ALGORITHM = 'Premium Algorithm',
  ELIGIBILITY = 'Eligibility',
  CLASS_TYPE = 'Class Type',
  OTHER_TYPE = 'Other Type',
}

const mapDisplayNameAttribute = {
  aboveLineFlag: 'Above The Line',
  creditFlag: 'State Code Credit',
  employerLiabilityBaseFlag: 'Employer Liability Base',
  includeABACUSStandardPremiumFlag: 'Include Standard Premium',
  checkDeductibleFlag: 'Check Deductible',
  nonStandardFlag: 'Non-Standard',

  cpapEligibleFlag: 'CPAP Eligible',
  exrEligibleFlag: 'EXR Eligible',
  inspectionEligibleFlag: 'Inspection Eligible',
  auditFlag: 'Audit Eligible',

  perCapitaFlag: 'Per-capita',
  populationFlag: 'Population',
  fCodeFlag: 'Federal Class',
  statisticalCodeFlag: 'Stat Code',
  aRateELRDFlag: 'A Rate/ELRD',

  overrideELRDFlag: 'ELR Override',
  allowLossFlag: 'Claim on Code',
  allowExposureFlag: 'Exposure Required',
  totalExposureFlag: 'Total Exposure Required',
}

const premiumAlgKeys = [
  'aboveLineFlag',
  'creditFlag',
  'employerLiabilityBaseFlag',
  'includeABACUSStandardPremiumFlag',
  'checkDeductibleFlag',
  'nonStandardFlag',
]
const eligibilityKeys = ['cpapEligibleFlag', 'exrEligibleFlag', 'inspectionEligibleFlag', 'auditFlag']
const classTypeKeys = ['perCapitaFlag', 'populationFlag', 'fCodeFlag', 'statisticalCodeFlag', 'aRateELRDFlag']
const otherTypeKeys = ['overrideELRDFlag', 'allowLossFlag', 'allowExposureFlag', 'totalExposureFlag']

export const getGroupedClassAttributes = (
  classDetails: ClassDetails | undefined
): { [key: string]: ClassAttribute[] } => {
  const attributeSet: { [key: string]: ClassAttribute[] } = {
    [ClassAttributeEnum.PREMIUM_ALGORITHM]: [],
    [ClassAttributeEnum.ELIGIBILITY]: [],
    [ClassAttributeEnum.CLASS_TYPE]: [],
    [ClassAttributeEnum.OTHER_TYPE]: [],
  }

  if (!classDetails) {
    return attributeSet
  }

  const classAttributes = classDetails.attributes

  for (const flag in classAttributes) {
    let currentAttributeGroup = undefined

    if (premiumAlgKeys.includes(flag)) {
      currentAttributeGroup = attributeSet[ClassAttributeEnum.PREMIUM_ALGORITHM]
    } else if (eligibilityKeys.includes(flag)) {
      currentAttributeGroup = attributeSet[ClassAttributeEnum.ELIGIBILITY]
    } else if (classTypeKeys.includes(flag)) {
      currentAttributeGroup = attributeSet[ClassAttributeEnum.CLASS_TYPE]
    } else if (otherTypeKeys.includes(flag)) {
      currentAttributeGroup = attributeSet[ClassAttributeEnum.OTHER_TYPE]
    }

    if (!currentAttributeGroup) {
      //attribute not supported
    } else {
      currentAttributeGroup.push({
        attribute: mapDisplayNameAttribute[flag as keyof typeof mapDisplayNameAttribute],
        enabled: classAttributes[flag as keyof typeof classAttributes],
      })
    }
  }
  return attributeSet
}
