import { MRT_ColumnDef } from 'material-react-table'
import CarrierLossCostMultiplier from '../../../../models/Carrier/CarrierLossCostMultiplier'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

export const CarrierLossCostMultiplierTableColumns: MRT_ColumnDef<CarrierLossCostMultiplier>[] = [
  {
    accessorKey: 'lossCostMultiplier',
    header: 'LCM Factor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 3),
    maxSize: 30,
  },
  {
    accessorKey: 'lossCostMultiplierEffectiveDate',
    header: 'Effective Date',
    size: 50,
  },
  {
    accessorKey: 'lossCostMultiplierExpirationDate',
    header: 'Expiration Date',
    size: 800,
  },
]

export default CarrierLossCostMultiplierTableColumns
