import { NoResultsFound, PhoenixBaseTable } from 'componix'
import React from 'react'
import { RateLossActLimitResponse } from '../../../../models/Rate/RateLossActLimitResponse'
import RateLossLimitsTableColumns from './RateLossLimitsTableColumns'

interface RateLossLimitsTableProps {
  data: RateLossActLimitResponse[]
}

export const RateLossLimitsTable = ({ data }: RateLossLimitsTableProps) => {
  return !data?.length ? (
    <NoResultsFound message="Not applicable for this rate period." />
  ) : (
    <PhoenixBaseTable columns={RateLossLimitsTableColumns} data={data} />
  )
}

export default RateLossLimitsTable
