import { Box, Switch, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { PaginationOptions, PhoenixBaseTable, PhoenixLink, createMRTPagination } from 'componix'
import dayjs from 'dayjs'
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import React, { useMemo } from 'react'
import { useBoolean } from '../../hooks/useBoolean'
import { ClassSummaryResponse } from '../../models/Class/ClassSummaryResponse'

const columns: MRT_ColumnDef<ClassSummaryResponse>[] = [
  {
    header: 'Class Code',
    accessorKey: 'classCode',
    Cell: (table) => <PhoenixLink to={`/classes/${table.row.original.classID}`} value={table.row.original.classCode} />,
    size: 80,
  },
  {
    header: 'Phraseology',
    accessorKey: 'classDescription',
  },
  {
    header: 'Effective Date',
    accessorKey: 'classEffectiveDate',
    size: 80,
  },
  {
    header: 'Expiration Date',
    accessorKey: 'classExpirationDate',
  },
]

interface ClassesTableProps {
  data: ClassSummaryResponse[]
  isLoading: boolean
}

const ClassesTable = ({ data, isLoading }: ClassesTableProps) => {
  const { value: showedExpiredClasses, onToggle: toggleshowedExpiredClasses } = useBoolean(false)

  const activeClassList = useMemo(
    () =>
      data.filter((carrier) => {
        const isClassExpired = dayjs(carrier.classExpirationDate).isBefore(dayjs())
        return !isClassExpired
      }),
    [data.length]
  )

  const customPagination = useMemo(
    () =>
      createMRTPagination(20, data.length, [
        PaginationOptions.FIVE,
        PaginationOptions.TEN,
        PaginationOptions.TWENTY,
        PaginationOptions.ALL,
      ]),
    [data.length]
  )

  return (
    <PhoenixBaseTable
      columns={columns}
      data={showedExpiredClasses ? data : activeClassList}
      isLoading={isLoading}
      overrides={{
        enableFilters: true,
        enableTopToolbar: true,
        enableGlobalFilter: true,
        renderTopToolbar: ({ table }) => {
          return (
            <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Box display={'flex'} flexGrow={1}>
                <MRT_GlobalFilterTextField table={table} />
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <FormControlLabel
                  sx={{ marginLeft: '4px' }}
                  control={<Switch checked={showedExpiredClasses} onChange={toggleshowedExpiredClasses} />}
                  label={<Typography variant={'body2'}>Show Expired Classes</Typography>}
                />
              </Box>
              <Box>{/*Add Buttons Here*/}</Box>
            </Box>
          )
        },
        muiTableBodyRowProps: ({ row }) => {
          const isClassExpired = dayjs(row.original.classExpirationDate).isBefore(dayjs())

          return {
            sx: {
              '& > .MuiTableCell-root': {
                color: isClassExpired ? '#A5A4A5' : 'normal',
              },
            },
          }
        },
        ...customPagination,
      }}
    />
  )
}

export default ClassesTable
