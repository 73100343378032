import { Box } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import React from 'react'
import { useGetRateList } from '../cache/rateCache'
import RatesTable from '../components/Rates/RatesTable'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

const Rates = () => {
  const { pageStyle } = useNavbar()
  const { data = [], isPending, isError } = useGetRateList()

  useSetAlertDetails([isError])

  return (
    <Box style={{ ...pageStyle, marginBottom: '24px' }}>
      <PhoenixBaseCard cardTitle={'Rate Data'}>
        <RatesTable data={data} isLoading={isPending} />
      </PhoenixBaseCard>
    </Box>
  )
}

export default Rates
