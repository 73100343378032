import { Person } from '@mui/icons-material'
import WorkIcon from '@mui/icons-material/Work'
import { Box, Grid } from '@mui/material'
import { FieldGroup, FieldGroupAddress, PhoenixLink } from 'componix'
import { MovePolicyResponseModel } from '../../../models/MovePolicyResponse'
import { SuggestedMatchModel } from '../../../models/WorkItemDetails/SuggestedMatchModel'
import { handleTitleCase } from '../../../utils/handleTitleCase'

interface FoundRecordContentProps {
  matchData: SuggestedMatchModel | null
  moveData: MovePolicyResponseModel | undefined
}

const FoundRecordContent = ({ matchData, moveData }: FoundRecordContentProps) => {
  return (
    <Box p={1} width={'100%'}>
      <Grid container>
        <Grid item xs={2}>
          {!moveData ? (
            <FieldGroup label={'Combo ID'} value={matchData?.combo?.identifier} />
          ) : (
            <FieldGroup
              label={'Combo ID'}
              value={<PhoenixLink to={`/combos/${moveData.comboGuid}`} value={`${moveData.comboId}`} />}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {!moveData ? (
            <FieldGroup label={'Coverage ID'} value={matchData?.coverage?.identifier} />
          ) : (
            <FieldGroup
              label={'Coverage ID'}
              value={<PhoenixLink to={`/coverages/${moveData.coverageId}`} value={`${moveData.coverageId}`} />}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <FieldGroup icon={<Person />} label={'Primary Name'} value={handleTitleCase(matchData?.employer)} />
        </Grid>
        <Grid item xs={3}>
          <FieldGroupAddress
            city={handleTitleCase(matchData?.address.city)}
            state={handleTitleCase(matchData?.address.state)}
            street={handleTitleCase(matchData?.address.street)}
            zipCode={handleTitleCase(matchData?.address.zipCode)}
          />
        </Grid>
        <Grid item xs={2}>
          <FieldGroup icon={<WorkIcon />} label={'FEIN'} value={matchData?.fein} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default FoundRecordContent
