import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Divider, Tab } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import { useNavigate } from 'react-router-dom'
import { useTabs } from '../../../hooks/useTabs'
import { EmptyAddressDTOModel } from '../../../models/AddressDTOModel'
import { MovePolicyResponseModel } from '../../../models/MovePolicyResponse'
import { Policy } from '../../../models/Policy'
import { MatchingCoverageResponse } from '../../../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../../../models/WorkItemDetails/SuggestedMatchModel'
import FindMatchButtonGroup, { StageOfFindMatchGroupButtonEnum } from './FindMatchButtonGroup'
import FindMatchComboForm from './FindMatchComboForm'
import FindMatchCoverageForm from './FindMatchCoverageForm'
import FoundRecordContent from './FoundRecordContent'

interface FindMatchFormsTabVersionProps {
  match: SuggestedMatchModel | null
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  backToCoverage: () => void
  handleConfirmMove: () => void
  isMatchLoading: boolean
  successMove: MovePolicyResponseModel[] | null
  policiesToMove: Policy[] | null
}

const FindMatchFormsTabVersion = ({
  match,
  setFoundMatch,
  isMatchLoading,
  backToCoverage,
  handleConfirmMove,
  successMove,
  policiesToMove,
}: FindMatchFormsTabVersionProps) => {
  const navigate = useNavigate()

  const { tabIndex, handleTabChange } = useTabs()

  const mapResponseToMatch = (data: MatchingCoverageResponse | null): SuggestedMatchModel => ({
    suggestionID: '',
    fein: data?.fein ?? '',
    combo: {
      identifier: data?.comboId ?? 'New',
      guid: data?.comboGuid ?? '',
    },
    coverage: {
      identifier: data?.coverageId ?? 'New',
      guid: data?.coverageGuid ?? '',
    },
    employer: data?.primaryName ?? '',
    address: data?.address ?? EmptyAddressDTOModel,
    open: true,
  })

  return match ? (
    <PhoenixBaseCard cardTitle={'Move to'} variantType={'Secondary'}>
      <FoundRecordContent matchData={match} moveData={successMove?.[0]} />
      {!successMove?.length ? (
        <FindMatchButtonGroup
          currentStage={StageOfFindMatchGroupButtonEnum.REVIEW}
          backToPrev={() => {
            setFoundMatch(null)
          }}
          backToCoverage={backToCoverage}
          handleConfirmMove={handleConfirmMove}
          isConfirmDisable={isMatchLoading}
          isConfirmLoading={isMatchLoading}
        />
      ) : (
        <FindMatchButtonGroup
          currentStage={StageOfFindMatchGroupButtonEnum.CONFIRM}
          toMovedCoverage={() => navigate(`/coverages/${successMove[0].coverageId}`)}
        />
      )}
    </PhoenixBaseCard>
  ) : (
    <PhoenixBaseCard cardTitle={'Move to'} variantType={'Secondary'} contentPadded>
      <TabContext value={tabIndex}>
        <Box width={'100%'}>
          <TabList onChange={handleTabChange}>
            <Tab label={'Existing Coverage'} value="0"></Tab>
            <Tab label={'New Coverage'} value="1"></Tab>
          </TabList>
        </Box>
        <Divider />
        <Box width={'100%'}>
          <TabPanel value="0" sx={{ padding: 0 }}>
            <FindMatchCoverageForm
              backToCoverage={backToCoverage}
              mapResponseToMatch={mapResponseToMatch}
              setFoundMatch={setFoundMatch}
              hasSelectedPolices={!!policiesToMove?.length}
            />
          </TabPanel>

          <TabPanel value="1" sx={{ padding: 0 }}>
            <FindMatchComboForm
              backToCoverage={backToCoverage}
              mapResponseToMatch={mapResponseToMatch}
              setFoundMatch={setFoundMatch}
              hasSelectedPolices={!!policiesToMove?.length}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </PhoenixBaseCard>
  )
}

export default FindMatchFormsTabVersion
