import { useQuery } from '@tanstack/react-query'
import { DatePeriodResponse } from '../models/DatePeriodResponse'
import { RateLossActLimitResponse } from '../models/Rate/RateLossActLimitResponse'
import { RatePeriodResponse } from '../models/Rate/RatePeriodResponse'
import { RatePremiumDiscountResponse } from '../models/Rate/RatePremiumDiscountResponse'
import { RateSplitPointResponse } from '../models/Rate/RateSplitPointResponse'
import { RateWeightsAndBallastsResponse } from '../models/Rate/RateWeightsAndBallastsResponse'
import {
  getRateData,
  getRateList,
  getRateLossLimits,
  getRatePremiumDiscounts,
  getRateSplitPoints,
  getRateWeightsAndBallasts,
} from '../services/rateService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetRateList = () =>
  useQuery<RatePeriodResponse[]>({
    queryKey: [CacheKeys.Rates],
    queryFn: getRateList,
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetRateData = (rateId: string) =>
  useQuery<RatePeriodResponse>({
    queryKey: [CacheKeys.Rate, rateId],
    queryFn: () => getRateData(rateId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!rateId,
  })

const useGetRateLossLimits = (ratePeriod: DatePeriodResponse) =>
  useLazyQuery<RateLossActLimitResponse[]>({
    queryKey: [CacheKeys.RatesLossLimits, ratePeriod],
    queryFn: () => getRateLossLimits(ratePeriod),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!ratePeriod?.effectiveDate,
  })

const useGetRateWeightsAndBallasts = (ratePeriod: DatePeriodResponse) =>
  useLazyQuery<RateWeightsAndBallastsResponse>({
    queryKey: [CacheKeys.RateWeightsAndBallasts, ratePeriod],
    queryFn: () => getRateWeightsAndBallasts(ratePeriod),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!ratePeriod?.effectiveDate,
  })

const useGetRatePremiumDiscounts = (ratePeriod: DatePeriodResponse) =>
  useLazyQuery<RatePremiumDiscountResponse[]>({
    queryKey: [CacheKeys.RatePremiumDiscounts, ratePeriod],
    queryFn: () => getRatePremiumDiscounts(ratePeriod),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!ratePeriod?.effectiveDate,
  })

const useGetRateSplitPoints = (ratePeriod: DatePeriodResponse) =>
  useQuery<RateSplitPointResponse[]>({
    queryKey: [CacheKeys.RateSplitPoints, ratePeriod],
    queryFn: () => getRateSplitPoints(ratePeriod),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!ratePeriod?.effectiveDate,
  })

export {
  useGetRateList,
  useGetRateLossLimits,
  useGetRateData,
  useGetRateWeightsAndBallasts,
  useGetRatePremiumDiscounts,
  useGetRateSplitPoints,
}
