import { useMutation, useQuery } from '@tanstack/react-query'
import { ComboGroupMembership } from '../models/ComboGroupMembership'
import { ControllingCoverageForModifyDetails, ControllingCoverageOptions } from '../models/ControllingCoverageOptions'
import { ComboCoverageResponse } from '../models/CoverageGroupModels'
import { CoverageGroupOwnershipModel } from '../models/CoverageGroupOwnershipModel'
import { CoverageGroupProfileResponse } from '../models/CoverageGroupProfileResponse'
import { MoveCoverageRequest, RatingsImpactedRequest, RatingsImpactedResponse } from '../models/CoverageMovement'
import { ModifyCoverageDateRequest, RatingsImpactedFromModifyCoverageResponse } from '../models/ModifyCoverageModels'
import {
  getAffectedRatingForModifyDetails,
  getComboMembershipHistory,
  getControllingCoverageOptions,
  getControllingCoverageOptionsForModifyDetails,
  getCoverageDataByCoverageId,
  getCoverageGroupOwnership,
  getCoverageGroupProfile,
  getRatingsImpacted,
  modifyCoverageIncludeDate,
  moveCoverageToExistingCombo,
  moveCoverageToNewCombo,
} from '../services/coverageService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetCoverageDataByComboId = (comboId: string, coverageId?: string) => {
  return useQuery<ComboCoverageResponse>({
    queryKey: [CacheKeys.CoverageDataByComboID, comboId, coverageId],
    queryFn: () => getCoverageDataByCoverageId(String(comboId), coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })
}

const useGetControllingCoverageOptions = (coverageId: string, originComboGuid: string, destinationComboGuid?: string) =>
  useQuery<ControllingCoverageOptions>({
    queryKey: [CacheKeys.ControllingCoverageOptions, coverageId, originComboGuid, destinationComboGuid],
    queryFn: () => getControllingCoverageOptions(coverageId, originComboGuid, destinationComboGuid),
    enabled: false,
  })

const useGetRatingsImpacted = (coverageId: string, movementImpactRequest: RatingsImpactedRequest) =>
  useQuery<RatingsImpactedResponse>({
    queryKey: [
      CacheKeys.RatingsImpacted,
      coverageId,
      movementImpactRequest.destinationCombo,
      movementImpactRequest.transitionDate,
    ],
    queryFn: () => getRatingsImpacted(coverageId, movementImpactRequest),
    initialData: { origin: [], destination: [] },
    enabled: false,
  })

const useGetComboMembershipHistory = (coverageId: string) =>
  useLazyQuery<ComboGroupMembership[]>({
    queryKey: [CacheKeys.ComboGroupMembershipHistory, coverageId],
    queryFn: () => getComboMembershipHistory(coverageId),
    staleTime: StaleTime.FIVE_MINUTES,
  })

const useGetCoverageGroupOwnerShip = (coverageId: string) =>
  useLazyQuery<CoverageGroupOwnershipModel[]>({
    queryKey: [CacheKeys.CoverageGroupOwnership, coverageId],
    queryFn: () => getCoverageGroupOwnership(coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetCoverageGroupProfile = (coverageId: string) =>
  useLazyQuery<CoverageGroupProfileResponse>({
    queryKey: [CacheKeys.CoverageGroupProfile, coverageId],
    queryFn: () => getCoverageGroupProfile(coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!coverageId,
  })

const useGetRatingsImpactedForModifyCoverageIncludeDate = (coverageId: string, newIncludeDate: string) =>
  useQuery<RatingsImpactedFromModifyCoverageResponse[]>({
    queryKey: [CacheKeys.RatingsImpacted, coverageId, newIncludeDate],
    queryFn: () => getAffectedRatingForModifyDetails(coverageId, newIncludeDate),
    staleTime: StaleTime.THIRTY_MINUTES,
    initialData: [],
    enabled: false,
  })

const useGetControllingCoverageOptionsForModifyCoverageIncludeDate = (coverageId: string, newInclude: string) =>
  useQuery<ControllingCoverageForModifyDetails[]>({
    queryKey: [CacheKeys.ControllingCoverageOptions, coverageId, newInclude],
    queryFn: () => getControllingCoverageOptionsForModifyDetails(coverageId, newInclude),
    enabled: false,
    initialData: [],
  })

// ====== MUTATIONS ======

const useMoveCoverage = (coverageId: string, moveCoverageRequest: MoveCoverageRequest) =>
  useMutation({
    mutationKey: [
      CacheKeys.MoveCoverage,
      coverageId,
      moveCoverageRequest.originControllingCoverageId,
      moveCoverageRequest.destinationControllingCoverageId,
      moveCoverageRequest.destinationComboGuid,
    ],
    mutationFn: (isNewCombo: boolean) => {
      return isNewCombo
        ? moveCoverageToNewCombo(coverageId, moveCoverageRequest)
        : moveCoverageToExistingCombo(coverageId, moveCoverageRequest)
    },
  })

const useModifyCoverageIncludeDate = (coverageId: string, request: ModifyCoverageDateRequest) =>
  useMutation({
    mutationKey: [CacheKeys.ModifyCoverageIncludeDate, coverageId],
    mutationFn: () => modifyCoverageIncludeDate(coverageId, request),
  })

export {
  useGetCoverageDataByComboId,
  useGetControllingCoverageOptions,
  useGetRatingsImpacted,
  useGetComboMembershipHistory,
  useGetCoverageGroupOwnerShip,
  useMoveCoverage,
  useGetCoverageGroupProfile,
  useGetRatingsImpactedForModifyCoverageIncludeDate,
  useGetControllingCoverageOptionsForModifyCoverageIncludeDate,
  useModifyCoverageIncludeDate,
}
