import InfoIcon from '@mui/icons-material/Info'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useFindMatchingCombo } from '../../../cache/workQueueCache'
import { MatchingCoverageResponse } from '../../../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../../../models/WorkItemDetails/SuggestedMatchModel'
import FindMatchButtonGroup, { StageOfFindMatchGroupButtonEnum } from './FindMatchButtonGroup'

interface IFormComboInput {
  comboId: string
}

interface FoundRecordContentProps {
  mapResponseToMatch: (response: MatchingCoverageResponse | null) => SuggestedMatchModel
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  backToCoverage: () => void
  hasSelectedPolices: boolean
}

const FindMatchComboForm = ({
  mapResponseToMatch,
  setFoundMatch,
  backToCoverage,
  hasSelectedPolices,
}: FoundRecordContentProps) => {
  const { control, handleSubmit, register, watch, setError } = useForm({
    mode: 'all',
    defaultValues: {
      comboId: '',
    },
  })
  const comboIdValidation = {
    maxLength: {
      message: 'Combo ID must not contain more than 9 characters.',
      value: 9,
    },
    pattern: {
      message: 'Non numeric characters are not allowed.',
      value: /^\d*$/,
    },
  }

  const formComboId = watch('comboId')

  const { refetch: fetchMatchingCombo, isRefetching, isLoading } = useFindMatchingCombo(formComboId)

  const onSubmitFindMatchingCombo: SubmitHandler<IFormComboInput> = async (data) => {
    if (!data.comboId) {
      const match = mapResponseToMatch(null)
      setFoundMatch(match)
    } else {
      const result = await fetchMatchingCombo()
      if (result.data) {
        const match = mapResponseToMatch(result.data)
        setFoundMatch(match)
      } else {
        setError('comboId', { message: 'No records to display.' })
      }
    }
  }

  return (
    <Box display={'block'} width={'100%'} mt={2}>
      <form onSubmit={handleSubmit(onSubmitFindMatchingCombo)}>
        <Box display={'flex'} mb={2}>
          <Box width={'50%'}>
            <Controller
              name="comboId"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...register('comboId', comboIdValidation)}
                  inputProps={{ type: 'search' }}
                  fullWidth
                  label="Combo ID"
                  size={'small'}
                  variant={'outlined'}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Box>
          <Stack flexDirection={'row'} alignItems={'center'} width={'25%'}>
            <InfoIcon color={'secondary'} sx={{ mr: 1, ml: 2 }} />
            <Typography variant={'caption'} lineHeight={1.5}>
              The system will automatically assign a Combo ID if one is not entered.
            </Typography>
          </Stack>
        </Box>
        <FindMatchButtonGroup
          currentStage={StageOfFindMatchGroupButtonEnum.SELECT}
          backToCoverage={backToCoverage}
          isNextDisable={isRefetching || isLoading || !hasSelectedPolices}
          isNextLoading={isRefetching || isLoading}
        />
      </form>
    </Box>
  )
}

export default FindMatchComboForm
