import { MRT_ColumnDef } from 'material-react-table'
import { RateBallast } from '../../../../models/Rate/RateWeightsAndBallastsResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

const RateBallastsTableColumns: MRT_ColumnDef<RateBallast>[] = [
  {
    accessorKey: 'lossStart',
    header: 'Loss Start',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    minSize: 50,
    size: 80,
  },
  {
    accessorKey: 'lossEnd',
    header: 'Loss End',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    minSize: 50,
    size: 80,
  },
  {
    accessorKey: 'ballastFactor',
    header: 'Factor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
  },
]

export default RateBallastsTableColumns
