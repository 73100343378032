import { Box, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { Dayjs, isDayjs } from 'dayjs'
import { useWatch } from 'react-hook-form'
import { ControllingCoverageItem } from '../../../models/ControllingCoverageOptions'
import ControllingCoverageSelect from '../../ControllingCoverageSelect/ControllingCoverageSelect'

interface SetControllingCoverageSectionProps {
  activeStep: number
  originComboId: string | undefined
  originOptions: ControllingCoverageItem[] | undefined
  destinationOptions: ControllingCoverageItem[] | undefined
}

const SetControllingCoverageSection = ({
  activeStep,
  originComboId,
  originOptions = [],
  destinationOptions = [],
}: SetControllingCoverageSectionProps) => {
  const newIncludeDate: Dayjs = useWatch({ name: 'newIncludeDate' })
  const destinationComboId: string = useWatch({ name: 'comboId' })

  return activeStep >= 1 ? (
    <Box display={'flex'}>
      <PhoenixBaseCard cardTitle="Original Combo Controlling Coverage" variantType="Secondary" defaultWidth={6}>
        <Grid container p={1}>
          <Grid item xs={6}>
            <FieldGroup label={'Combo ID'} value={originComboId} />
          </Grid>
          <Grid item xs={6}>
            <FieldGroup label={'Transition Date'} value={newIncludeDate.format('MM/DD/YYYY')} />
          </Grid>
          <ControllingCoverageSelect
            activeStep={activeStep}
            fieldName="originControllingCoverage"
            options={originOptions}
          />
        </Grid>
      </PhoenixBaseCard>
      <PhoenixBaseCard cardTitle="Destination Combo Controlling Coverage" variantType="Secondary" defaultWidth={6}>
        <Grid container p={1}>
          <Grid item xs={6}>
            <FieldGroup label={'Combo ID'} value={destinationComboId || 'New'} />
          </Grid>
          <Grid item xs={6}>
            <FieldGroup
              label={'Transition Date'}
              value={isDayjs(newIncludeDate) ? newIncludeDate.format('MM/DD/YYYY') : ''}
            />
          </Grid>
          <ControllingCoverageSelect
            activeStep={activeStep}
            fieldName="destinationControllingCoverage"
            options={destinationOptions}
          />
        </Grid>
      </PhoenixBaseCard>
    </Box>
  ) : null
}
export default SetControllingCoverageSection
