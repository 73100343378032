import { Box, Divider, Grid, Skeleton, Stack } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { useFieldArray } from 'react-hook-form'
import {
  ControllingCoverageForModifyDetails,
  ControllingCoverageItem,
} from '../../../models/ControllingCoverageOptions'
import ControllingCoverageSelect from '../../ControllingCoverageSelect/ControllingCoverageSelect'

interface SetControllingCoverageSectionProps {
  activeStep: number
  combosAndOptions: ControllingCoverageForModifyDetails[]
  date?: string
  dateFieldName?: string
  isLoading: boolean
}

interface SetControllingCoverageItemProps {
  activeStep: number
  dateFieldName?: string
  dateValue?: string
  comboId?: string
  fieldName: string
  items: ControllingCoverageItem[]
}

export const SetControllingCoverageSection = ({
  activeStep,
  combosAndOptions,
  date,
  dateFieldName,
  isLoading,
}: SetControllingCoverageSectionProps) => {
  const { fields } = useFieldArray({
    name: `controllingCoverages`,
  })

  return (
    <>
      <PhoenixBaseCard cardTitle="Combos Impacted" variantType="Secondary" defaultWidth={12} isLoading={isLoading}>
        {isLoading && (
          <Stack width={'100%'} sx={{ m: 2 }} spacing={1}>
            <Skeleton variant="rounded" width={'100%'} height={40} />
          </Stack>
        )}
        {!isLoading && (
          <Box width={'100%'}>
            {combosAndOptions.map((comboAndOptions, index) => (
              <Box key={index} m={2}>
              <SetControllingCoverageItem
                activeStep={activeStep}
                key={index}
                dateFieldName={dateFieldName}
                dateValue={date}
                comboId={comboAndOptions.comboID}
                fieldName={`controllingCoverages[${index}]`}
                items={comboAndOptions.controllingCoverageChoices}
              />
              {index<combosAndOptions.length-1 &&
                <Divider/>
                }
              </Box>
            ))}
          </Box>
        )}
      </PhoenixBaseCard>
    </>
  )
}

export const SetControllingCoverageItem = ({
  activeStep,
  dateFieldName,
  dateValue,
  comboId,
  fieldName,
  items,
}: SetControllingCoverageItemProps) => {
  return (
    <Grid container p={1}>
      <Grid item xs={2}>
        <FieldGroup label={'Combo ID'} value={comboId} />
      </Grid>
      {dateValue && (
        <Grid item xs={3}>
          <FieldGroup label={dateFieldName ?? 'Transition Date'} value={dateValue} />
        </Grid>
      )}
      <Grid item xs={7}>
        <ControllingCoverageSelect activeStep={activeStep} fieldName={fieldName} options={items} />
      </Grid>
    </Grid>
  )
}
