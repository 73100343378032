import { List, Tab, Tabs } from '@mui/material'
import React from 'react'
import { NavTabRoutes } from './DrawerTabs'

interface NavTabProps {
  tabSelection: string
  handleChange: (event: React.SyntheticEvent, newValue: string) => void
  TabData: NavTabRoutes[]
}

export const NavTabs = ({ tabSelection, handleChange, TabData }: NavTabProps) => {
  return (
    <List
      sx={{
        marginTop: '64px',
        width: 'auto',
        overflow: 'hidden',
      }}
    >
      <Tabs
        value={tabSelection}
        orientation="vertical"
        onChange={handleChange}
        variant="scrollable"
        TabIndicatorProps={{
          sx: {
            left: 0,
            background: '#C8E8FF',
            width: '4px',
            height: '56px',
            marginLeft: '2px',
          },
        }}
      >
        {TabData.map((tab, index) => (
          <Tab
            key={index}
            iconPosition="start"
            icon={tab.icon as React.ReactElement}
            title={tab.title}
            value={tab.route}
            label={tab.labelText}
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              backgroundColor: '#C8E8FF26',
              marginRight: '2px',
              width: '78px',
              minHeight: '56px',
              justifyContent: 'start',
              textAlign: 'left',
              py: 0,
              px: 1,
            }}
          />
        ))}
      </Tabs>
    </List>
  )
}

interface TabLabelProps {
  icon: React.ReactNode | string
  labelText: string
}

export const TabLabel = ({ icon, labelText }: TabLabelProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {icon}
      <span
        style={{
          width: '58px',
          color: 'var(--Jet-Black, #0D1227)',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '0.1px',
          textTransform: 'capitalize',
        }}
      >
        {labelText}
      </span>
    </div>
  )
}
