import { CalendarToday } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import dayjs, { Dayjs, isDayjs } from 'dayjs'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  useGetControllingCoverageOptionsForModifyCoverageIncludeDate,
  useGetRatingsImpactedForModifyCoverageIncludeDate,
  useModifyCoverageIncludeDate,
} from '../../../cache/coverageCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { CombinedCoverageHistoryModel } from '../../../models/CombinedCoverageHistory'
import {
  ControllingCoverageForModifyDetails,
} from '../../../models/ControllingCoverageOptions'
import { handleTitleCase } from '../../../utils/handleTitleCase'
import RatingsImpactedSection from './RatingsImpactedSection'
import { SetControllingCoverageSection } from './SetControllingCoverageSection'

interface ModifyCoverageCardProps {
  sourceCoverageData?: CombinedCoverageHistoryModel
  oldestIncludeDate?: Dayjs | string
  onCancel: () => void
  isLoading?: boolean
}
interface IModifyCoverageFormProps {
  newIncludeDate: Dayjs | string
  affectedRatings: string[]
  controllingCoverages: string[]
}

export const ModifyCoverageCard = ({
  sourceCoverageData,
  onCancel,
  oldestIncludeDate,
  isLoading,
}: ModifyCoverageCardProps) => {
  const formMethods = useForm<IModifyCoverageFormProps>({
    defaultValues: {
      newIncludeDate: '',
    },
    mode: 'all',
  })

  const { control, handleSubmit, formState, watch, setValue } = formMethods
  const [activeStep, setActiveStep] = useState(0)
  const newIncludeDate = watch('newIncludeDate')
  const controllingCoverages = watch('controllingCoverages')
  const minDate = oldestIncludeDate ? dayjs(oldestIncludeDate) : dayjs(sourceCoverageData?.includeDate)
  const coverageId = sourceCoverageData?.coverageId.toString() ?? ''

  const {
    data: affectedRatingsData,
    refetch: fetchAffectedRatings,
    isRefetching: isAffectedRatingsRefetching,
    isLoading: isAffectedRatingsLoading,
    isError: isAffectedRatingsError,
  } = useGetRatingsImpactedForModifyCoverageIncludeDate(
    coverageId,
    isDayjs(newIncludeDate) ? newIncludeDate.format('YYYY-MM-DD') : ''
  )

  const {
    data: controllingCoverageOptions,
    refetch: fetchControllingCoverageOptions,
    isRefetching: isControllingCoverageOptionsRefetching,
    isLoading: isControllingCoverageOptionsLoading,
    isError: isControllingCoverageOptionsError,
  } = useGetControllingCoverageOptionsForModifyCoverageIncludeDate(
    coverageId,
    isDayjs(newIncludeDate) ? newIncludeDate.format('YYYY-MM-DD') : ''
  )
  const mapControllingCoverageResults = (
    controllingCoverageOptions: ControllingCoverageForModifyDetails[],
    controllingCoverages: string[]
  ) => {
    return controllingCoverages?.map((coverageId, index) => {
      const comboId = controllingCoverageOptions[index]?.comboID ?? ''
      return { comboId, controllingCoverageId: coverageId }
    })
  }
  const { mutateAsync: modifyCoverageIncludeDate, isError: isModifyCoverageIncludeDateError } =
    useModifyCoverageIncludeDate(coverageId, {
      includeDate: isDayjs(newIncludeDate) ? newIncludeDate.format('YYYY-MM-DD') : '',
      affectedRatings: affectedRatingsData?.map((rating) => [...rating?.affectedRatings?.map((r) => r.ratingGuid)]).flat() ?? [],
      controllingCoverages: mapControllingCoverageResults(controllingCoverageOptions, controllingCoverages),
    })
  const isLoadingData =
    isAffectedRatingsLoading ||
    isAffectedRatingsRefetching ||
    isControllingCoverageOptionsLoading ||
    isControllingCoverageOptionsRefetching

  const fetchAndSetInitialControllingCoverageOptions = async () => {
    const { data } = await fetchControllingCoverageOptions()
    const initialControllingCoverageOptions = data?.map(
      (item) =>
        item.controllingCoverageChoices.find((option) => option.selectionReasoning)?.coverageId?.toString() ?? ''
    )
    setValue('controllingCoverages', initialControllingCoverageOptions ?? [])
  }

  const confirmNewIncludeDate = async () => {
    setActiveStep(1)
    fetchAndSetInitialControllingCoverageOptions()
  }
  const confirmControllingCoverage = async () => {
    fetchAffectedRatings()
    setActiveStep(2)
  }
  const submitCoverageModification = async () => {
    modifyCoverageIncludeDate()
  }

  const onSubmit = async () => {
    switch (activeStep) {
      case 0:
        confirmNewIncludeDate()
        break
      case 1:
        confirmControllingCoverage()
        break
      case 2:
        submitCoverageModification()
        break
      default:
        break
    }
  }

  useSetAlertDetails([isAffectedRatingsError, isControllingCoverageOptionsError, isModifyCoverageIncludeDateError])
  return (
    <PhoenixBaseCard cardTitle={'Modify Coverage'} contentPadded>
      <Box width={'100%'}>
        <Stack direction={'row'} spacing={2} sx={{ m: 2 }}>
          <FieldGroup
            label={'Include Date'}
            value={sourceCoverageData?.includeDate}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Exclude Date'}
            value={sourceCoverageData?.excludeDate}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Controlling Start Date'}
            value={handleTitleCase(sourceCoverageData?.controllingStartDate)}
            icon={<CalendarToday />}
            isLoading={isLoading ?? false}
          />
          <FieldGroup
            label={'Controlling End Date'}
            value={sourceCoverageData?.controllingEndDate}
            isLoading={isLoading ?? false}
            icon={<CalendarToday />}
          />
        </Stack>
      </Box>
      <Box width={'100%'} component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <PhoenixBaseCard cardTitle={'Update'} variantType="Secondary" contentPadded>
            {activeStep == 0 && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="newIncludeDate"
                  control={control}
                  rules={{
                    required: 'New Include Date is required',
                    validate: (date: Dayjs | string) => {
                      if (isDayjs(date)) {
                        if (!date.isValid()) {
                          return 'Invalid Date'
                        }
                        if (date.diff(minDate, 'day') <= 0) {
                          return `New Include Date must occur after include date for previous coverage combination of: ${minDate.format('MM/DD/YYYY')}.`
                        }
                        if (date.diff(dayjs(), 'day') >= 365) {
                          return 'New Include Date must not exceed 12 months from current date.'
                        }
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Grid container p={1}>
                      <Grid item xs={2}>
                        <DatePicker
                          sx={{ m: 2 }}
                          label="New Include Date"
                          shouldDisableDate={(date: Dayjs) => {
                            return date.diff(minDate, 'day') <= 0 || date.diff(dayjs(), 'day') >= 365
                          }}
                          minDate={minDate}
                          maxDate={dayjs().add(1, 'year')}
                          onChange={(date) => field.onChange(date)}
                          slotProps={{
                            textField: {
                              value: field.value,
                              size: 'small',
                              fullWidth: true,
                              error: fieldState.invalid,
                              helperText: fieldState.error?.message,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            )}
            {activeStep >= 1 && (
              <Box sx={{ m: 2 }}>
                <FieldGroup
                  label={'New Include Date'}
                  value={isDayjs(newIncludeDate) ? newIncludeDate?.format('MM/DD/YYYY') : ''}
                />
              </Box>
            )}
          </PhoenixBaseCard>
          {activeStep > 0 && (
            <SetControllingCoverageSection
              activeStep={activeStep}
              date={isDayjs(newIncludeDate) ? newIncludeDate?.format('MM/DD/YYYY') : ''}
              combosAndOptions={controllingCoverageOptions}
              isLoading={isControllingCoverageOptionsLoading || isControllingCoverageOptionsRefetching}
            />
          )}
          {activeStep > 1 && (
            <>
              <RatingsImpactedSection
                ratingsImpacted={affectedRatingsData}
                isLoading={isAffectedRatingsLoading || isAffectedRatingsRefetching}
              />
            </>
          )}
          <Stack direction="row" spacing={2} sx={{ m: 2, justifyContent: 'end' }}>
            <Button color="primary" onClick={onCancel}>
              Back To Combo
            </Button>
            {activeStep > 0 && (
              <Button color="primary" variant="contained" onClick={() => setActiveStep(activeStep - 1)}>
                Prev
              </Button>
            )}
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoadingData}
              disabled={!formState.isDirty || !formState.isValid}
            >
              {activeStep === 2 ? 'Confirm Changes' : 'Next'}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </PhoenixBaseCard>
  )
}
