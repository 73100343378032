import { BeachAccess, PeopleAlt, ScaleOutlined, Search } from '@mui/icons-material'
import InboxIcon from '@mui/icons-material/Inbox'
import React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavTabs } from './NavTabs'

export interface NavTabRoutes {
  route: string
  labelText: string
  icon: React.ReactNode | string
  title: string
}

const TabRoutes: NavTabRoutes[] = [
  { route: 'workqueue', labelText: 'Work Queue', icon: <InboxIcon />, title: 'WorkQueueTab' },
  { route: 'search', labelText: 'Search', icon: <Search />, title: 'SearchTab' },
  { route: 'carriers', labelText: 'Carrier', icon: <BeachAccess />, title: 'CarrierTab' },
  { route: 'classes', labelText: 'Class', icon: <PeopleAlt />, title: 'ClassTab' },
  { route: 'rates', labelText: 'Rate Data', icon: <ScaleOutlined />, title: 'RateTab' },
]

const DrawerTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [tabSelection, setTabSelection] = useState('')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabSelection(newValue)
    navigate(`/${newValue}`)
  }

  /* istanbul ignore next */
  useEffect(() => {
    const path = location.pathname.replace('/', '')
    // This can later be replaced with if else, if we end up using other tabs or urls
    if (TabRoutes.some((tab) => tab.route === path)) {
      setTabSelection(path)
    } else {
      setTabSelection('')
    }

    // TODO remove location as dependency and substitute it
  }, [location])

  return <NavTabs tabSelection={tabSelection} handleChange={handleChange} TabData={TabRoutes} />
}
export default DrawerTabs
