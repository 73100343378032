import { PhoenixBaseTable } from 'componix'
import React from 'react'
import { ClassSplitPointAndDRatioResponse } from '../../../../models/Class/ClassSplitPointAndDRatioResponse'
import ClassSplitPointAndRatioTableColumns from './ClassSplitPointAndRatioTableColumns'

interface ClassSplitPointAndRatioTableProps {
  data: ClassSplitPointAndDRatioResponse[]
  isLoading: boolean
}

const ClassSplitPointAndRatioTable = ({ data, isLoading }: ClassSplitPointAndRatioTableProps) => {
  return <PhoenixBaseTable columns={ClassSplitPointAndRatioTableColumns} data={data} isLoading={isLoading} searchable />
}

export default ClassSplitPointAndRatioTable
