import { PhoenixLink, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import PolicyDetailsEndorsementsModel from '../../../models/PolicyDetails/PolicyDetailsEndorsementsModel'

/* eslint react/prop-types: 0 */
export const PolicyEndorsementsTableColumns: MRT_ColumnDef<PolicyDetailsEndorsementsModel>[] = [
  {
    accessorKey: 'endorsement',
    header: 'Endorsement Number',
    Cell: ({ row }) => {
      return <PhoenixLink to={`/endorsements/${row.original.endorsementID}`} value={row.original.endorsementNumber} />
    },
  },
  {
    accessorKey: 'endorsementName',
    header: 'Endorsement Name',
    size: 250,
  },
  {
    accessorKey: 'extensionStatus',
    header: 'Extension',
    Cell: ({ row }) => {
      return <StatusBadge description={row.original.extensionStatus} />
    },
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective Date',
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration Date',
  },
  {
    accessorKey: 'receivedDate',
    header: 'Received Date',
  },
  {
    accessorKey: 'issuedDate',
    header: 'Issued Date',
  },
]

export default PolicyEndorsementsTableColumns
