import { Box, Button } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import { useGetCarrierLossCost } from '../../../cache/carrierCache'
import { useBoolean } from '../../../hooks/useBoolean'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import AddCarrierLossCostModal from '../../Modals/AddCarrierLossCostModal'
import CarrierLossCostMultiplierTable from './CarrierLossCostMultiplierTable'

interface CarrierLossCostMultiplierCardProps {
  guid: string | undefined
}

export const CarrierLossCostMultiplierCard = ({ guid }: CarrierLossCostMultiplierCardProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetCarrierLossCost(guid as string)
  const { value: isModalOpen, onTrue, onFalse } = useBoolean()
  useSetAlertDetails([isError])

  return (
    <>
      <PhoenixBaseCard cardTitle={'Loss Cost Multiplier'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
        {data?.length > 0 && (
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} margin="8px">
            <Button variant="contained" onClick={onTrue}>
              + Add Loss Cost Multiplier
            </Button>
          </Box>
        )}
        <CarrierLossCostMultiplierTable data={data} isLoading={isLoading} />
      </PhoenixBaseCard>
      <AddCarrierLossCostModal
        isOpen={isModalOpen}
        onCancel={onFalse}
        onConfirm={onFalse}
        latestLcmEffectiveDate={data ? data[0]?.lossCostMultiplierEffectiveDate : ''}
        carrierGuid={guid as string}
      />
    </>
  )
}

export default CarrierLossCostMultiplierCard
