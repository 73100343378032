import { MRT_ColumnDef } from 'material-react-table'
import { RateLossActLimitResponse } from '../../../../models/Rate/RateLossActLimitResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

const RateLossLimitsTableColumns: MRT_ColumnDef<RateLossActLimitResponse>[] = [
  {
    accessorKey: 'lossActCode',
    header: 'Loss Act',
    Cell: ({ cell }) => cell.getValue<number>().toString().padStart(2, '0'),
    size: 40,
  },
  {
    accessorKey: 'catastropheFlag',
    header: 'Catastrophe',
    Cell: ({ cell }) => (cell.getValue<boolean>() ? 'Y' : 'N'),
    size: 40,
  },
  {
    accessorKey: 'primaryLimit',
    header: 'Primary Limit',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 40,
  },
  {
    accessorKey: 'incurredLimit',
    header: 'Incurred Limit',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    size: 800,
  },
]

export default RateLossLimitsTableColumns
