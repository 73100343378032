import { PhoenixBaseTable } from 'componix'
import React from 'react'
import { RateSplitPointResponse } from '../../../../models/Rate/RateSplitPointResponse'
import RateSplitPointsTableColumns from './RateSplitPointsTableColumns'

interface RateSplitPointsTableProps {
  data: RateSplitPointResponse[]
  isLoading: boolean
}

const RateSplitPointsTable = ({ data, isLoading }: RateSplitPointsTableProps) => {
  return (
    <PhoenixBaseTable
      columns={RateSplitPointsTableColumns}
      data={data}
      isLoading={isLoading}
      searchable
      overrides={{
        enableGlobalFilter: false,
      }}
    />
  )
}

export default RateSplitPointsTable
