import { Box } from '@mui/material'
import { BreadcrumbBar, LayoutWithSideRail, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetComboDataByCoverageId } from '../cache/comboCache'
import { useGetPolicyListByCoverageId } from '../cache/policyCache'
import PoliciesTable from '../components/Coverage/PoliciesTable'
import { ExperienceRating } from '../components/ExperienceRating'
import FindMatchFormsTabVersion from '../components/FindMatch/FindMatchFormsTabVersion'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import RelatedCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useNavbar } from '../contexts/NavbarContext'
import { useMovePolicyToCoverage } from '../hooks/useMovePolicy'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { Policy } from '../models/Policy'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const MovePolicy = () => {
  const { coverageId } = useParams()
  const { pageStyle } = useNavbar()
  const navigate = useNavigate()

  const {
    data: comboData,
    isPending: isComboDataLoading,
    isError: isComboDataError,
  } = useGetComboDataByCoverageId(coverageId as string)

  const { data: ancestorData } = useGetAncestors(comboData?.coverageGuid as UUID, HierarchyTypes.COVERAGE)

  const {
    data: policyList,
    isPending: isPolicyListLoading,
    isError: isPolicyListError,
  } = useGetPolicyListByCoverageId(comboData?.coverageGuid as string)

  const { match, setFoundMatch, isMatchLoading, setPoliciesToMove, policiesToMove, handleConfirmMove, successMove } =
    useMovePolicyToCoverage({
      coverage: { guid: comboData?.coverageGuid, identifier: coverageId },
    })

  useSetAlertDetails([isPolicyListError, isComboDataError])

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${comboData?.comboId}`,
            to: `/combos/${comboData?.comboGuid}`,
          },
          {
            label: `Coverage ${coverageId}`,
            to: `/coverages/${coverageId}`,
          },
          {
            label: `Move Policies`,
            to: `/coverages/${coverageId}/move-policies`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <PhoenixBaseCard cardTitle={'Move Policies'}>
            <Box m={1.5} width={'100%'} minWidth={'98%'}>
              <PhoenixBaseCard cardTitle={match ? 'Selected Policies' : 'Select Policies'} variantType="Secondary">
                {match ? (
                  <PoliciesTable
                    data-testid="policies-table-final"
                    policyListData={policiesToMove as Policy[]}
                    isLoading={false}
                    includeMovePolicyButton={false}
                    allowRowSelection={false}
                  />
                ) : (
                  <PoliciesTable
                    data-testid="policies-table-select"
                    policyListData={policyList}
                    isLoading={isPolicyListLoading}
                    includeMovePolicyButton={false}
                    setPoliciesToMove={setPoliciesToMove}
                    allowRowSelection={true}
                  />
                )}
              </PhoenixBaseCard>

              <FindMatchFormsTabVersion
                setFoundMatch={setFoundMatch}
                match={match}
                isMatchLoading={isMatchLoading}
                backToCoverage={() => navigate(`/coverages/${coverageId}`)}
                handleConfirmMove={handleConfirmMove}
                successMove={successMove}
                policiesToMove={policiesToMove}
              />
            </Box>
          </PhoenixBaseCard>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key="pci"
            combo={ancestorData?.combo}
            coverage={ancestorData?.coverage}
            isLoading={isComboDataLoading}
          />,
          <ExperienceRating combo={ancestorData?.combo} key="er" />,
          <RelatedCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key="rcic"
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
