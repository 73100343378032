import { NoResultsFound, PhoenixBaseTable } from 'componix'
import React from 'react'
import { RatePremiumDiscountResponse } from '../../../../models/Rate/RatePremiumDiscountResponse'
import RatePremiumDiscountTableColumns from './RatePremiumDiscountTableColumns'

interface RatePremiumDiscountTableProps {
  data: RatePremiumDiscountResponse[]
}

export const RatePremiumDiscountTable = ({ data }: RatePremiumDiscountTableProps) => {
  return !data?.length ? (
    <NoResultsFound message="Not applicable for this rate period." />
  ) : (
    <PhoenixBaseTable columns={RatePremiumDiscountTableColumns} data={data} />
  )
}

export default RatePremiumDiscountTable
