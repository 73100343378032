import { useQuery } from '@tanstack/react-query'
import { ClassLossCostResponse } from '../models/Class/ClassLossCost'
import { DatePeriodResponse } from '../models/DatePeriodResponse'
import {
  getClassDetails,
  getClassList,
  getClassLossCost,
  getClassSplitPointDateList,
  getClassSplitPointsAndDRatios,
  getClassSummaryInfo,
} from '../services/classService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetClassList = () =>
  useQuery({
    queryKey: [CacheKeys.Classes],
    queryFn: getClassList,
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetClassSummaryInfo = (classId: string) =>
  useQuery({
    queryKey: [CacheKeys.ClassSummary, classId],
    queryFn: () => getClassSummaryInfo(classId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!classId,
  })

const useGetClassLossCost = (classId: string | undefined) =>
  useLazyQuery<ClassLossCostResponse[]>({
    queryKey: [CacheKeys.ClassLossCost, classId as string],
    queryFn: () => getClassLossCost(classId as string),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!classId,
  })

const useGetClassDetails = (classId: string) =>
  useQuery({
    queryKey: [CacheKeys.ClassDetails, classId],
    queryFn: () => getClassDetails(classId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!classId,
  })

const useGetClassSplitPointDateList = (classId: string) =>
  useQuery({
    queryKey: [CacheKeys.ClassSplitPointDateList, classId],
    queryFn: () => getClassSplitPointDateList(classId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!classId,
  })

const useGetClassSplitPointsAndDRatios = (classId: string, rateDate: DatePeriodResponse) =>
  useLazyQuery({
    queryKey: [CacheKeys.ClassSplitPointsAndDRatios, classId, rateDate],
    queryFn: () => getClassSplitPointsAndDRatios(classId, rateDate),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!classId && !!rateDate,
  })

export {
  useGetClassList,
  useGetClassSummaryInfo,
  useGetClassLossCost,
  useGetClassDetails,
  useGetClassSplitPointDateList,
  useGetClassSplitPointsAndDRatios,
}
