export enum HierarchyTypes {
  COMBO = 'combo',
  COVERAGE = 'coverage',
  POLICY = 'policy',
  UNITSTAT = 'unitStat',
  CLAIM = 'claim',
  RATING = 'rating',
  CARRIER = 'carrier',
  CLASS = 'class',
  ENDORSEMENT = 'endorsement',
}
