import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button } from '@mui/material'

export enum StageOfFindMatchGroupButtonEnum {
  SELECT = 'select',
  REVIEW = 'review',
  CONFIRM = 'confirm',
}

interface FindMatchButtonGroupProps {
  currentStage: StageOfFindMatchGroupButtonEnum
  backToPrev?: () => void
  backToCoverage?: () => void
  toMovedCoverage?: () => void
  handleConfirmMove?: () => void
  isNextLoading?: boolean
  isNextDisable?: boolean
  isConfirmLoading?: boolean
  isConfirmDisable?: boolean
}

const FindMatchButtonGroup = ({
  currentStage,
  backToPrev,
  backToCoverage,
  handleConfirmMove,
  toMovedCoverage,
  isNextLoading,
  isNextDisable,
  isConfirmLoading,
  isConfirmDisable,
}: FindMatchButtonGroupProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1} justifyContent={'end'} width={'100%'}>
      {currentStage != StageOfFindMatchGroupButtonEnum.CONFIRM && (
        <Button variant="text" onClick={backToCoverage}>
          BACK TO COVERAGE
        </Button>
      )}

      {currentStage == StageOfFindMatchGroupButtonEnum.SELECT && (
        <LoadingButton
          data-testid={'find-match-next-bttn'}
          disabled={isNextDisable}
          loading={isNextLoading}
          variant={'contained'}
          sx={{
            py: 1,
            px: 2,
            m: '2px',
            ml: 1,
            minWidth: '30px',
          }}
          type="submit"
        >
          NEXT
        </LoadingButton>
      )}

      {currentStage == StageOfFindMatchGroupButtonEnum.REVIEW && (
        <>
          <Button variant="contained" data-testid={'find-match-prev-bttn'} onClick={backToPrev}>
            PREV
          </Button>
          <LoadingButton
            data-testid="confirm-move-btn"
            disabled={isConfirmDisable}
            loading={isConfirmLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirmMove}
          >
            CONFIRM MOVE
          </LoadingButton>
        </>
      )}

      {currentStage == StageOfFindMatchGroupButtonEnum.CONFIRM && (
        <Button variant="contained" onClick={toMovedCoverage}>
          NAVIGATE TO COVERAGE
        </Button>
      )}
    </Box>
  )
}

export default FindMatchButtonGroup
