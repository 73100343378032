export const Endpoints = {
  Ancestors: '/api/ancestors',
  Attachments: '/api/attachments',
  Carriers: '/api/carriers',
  Claims: '/api/claims',
  Classes: '/api/classes',
  ComboId: '/api/coverages',
  Combos: '/api/combos',
  Coverages: '/api/coverages',
  Policy: '/api/policies',
  PolicySearchByCoverageId: '/api/coverages',
  Ratings: '/api/ratings',
  Rates: '/api/rates',
  UnitStat: '/api/unitstats',
  WorkQueue: '/api/workQueue',
  EndorsementDetails: '/api/EndorsementDetails',
}
