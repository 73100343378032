import { Person, Work } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { FieldGroup, FieldGroupAddress, PhoenixBaseCard, PhoenixLink } from 'componix'
import { useGetCoverageDataByComboId } from '../../cache/coverageCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import ControllingShield from '../../media/ControllingShield'
import { Ancestor } from '../../models/AncestorResponse'
import { handleTitleCase } from '../../utils/handleTitleCase'

interface PrimaryCoverageIdentifierProps {
  combo: Ancestor | null | undefined
  coverage?: Ancestor | null | undefined
  title?: string
  isLoading?: boolean
}

/**
 * PrimaryCoverageIdentifier is a left gutter card present throughout most of the Phoenix Hierarchy.
 * It displays the Entity name and Address for the primary coverage
 * (if at the combo level, this is the controlling coverage; otherwise it is the coverage whose data or children the user is viewing).
 * @param combo: Ancestor
 * @param coverage: Ancestor
 * @param title: (optional) string, defaults to "Coverage Group"
 */
export const PrimaryCoverageIdentifier = ({
  combo,
  coverage,
  title = 'Coverage Group',
  isLoading,
}: PrimaryCoverageIdentifierProps) => {
  const {
    data: coverageData,
    isPending: isCoverageDataLoading,
    isError: isCoverageDataError,
  } = useGetCoverageDataByComboId(combo?.guid as string, coverage?.guid)

  const isDataLoading = isCoverageDataLoading || isLoading

  useSetAlertDetails([isCoverageDataError])
  if (!isDataLoading && coverageData?.primary?.coverageInfo == null) {
    return (
      <PhoenixBaseCard cardTitle={title} contentPadded>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1" title="NoRelatedContent">
            No coverage data exists.
          </Typography>
        </Box>
      </PhoenixBaseCard>
    )
  }

  const primaryCoverageId = coverageData?.primary?.coverageInfo?.id
  const renderShield = coverageData?.primary?.coverageInfo?.controlling

  return (
    <PhoenixBaseCard cardTitle={title} contentPadded={true}>
      <Grid container p={1}>
        <Grid item lg={6} xs={12}>
          <FieldGroup
            label={'Combo ID'}
            isLoading={isCoverageDataLoading}
            value={<PhoenixLink to={`/combos/${combo?.guid}`} value={combo?.identifier} />}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <FieldGroup
            label={'Coverage ID'}
            isLoading={isCoverageDataLoading}
            value={
              <Grid id={'ControllingShield'} item>
                {renderShield && <ControllingShield />}
                <span style={{ verticalAlign: 'top' }}>
                  <PhoenixLink to={`/coverages/${primaryCoverageId}`} value={primaryCoverageId} />
                </span>
              </Grid>
            }
          />
        </Grid>
        <FieldGroup
          label="Primary Name"
          value={handleTitleCase(coverageData?.primary?.coverageInfo?.name)}
          icon={<Person />}
          multiLineWrap
          isLoading={isCoverageDataLoading}
        />
        <FieldGroupAddress
          street={handleTitleCase(coverageData?.primary?.address?.street)}
          city={handleTitleCase(coverageData?.primary?.address?.city)}
          state={handleTitleCase(coverageData?.primary?.address?.state)}
          zipCode={coverageData?.primary?.address?.zipCode}
          isLoading={isCoverageDataLoading}
        />
        <FieldGroup label="FEIN" value={coverageData?.primary?.fein} icon={<Work />} isLoading={isDataLoading} />
      </Grid>
    </PhoenixBaseCard>
  )
}

export default PrimaryCoverageIdentifier
