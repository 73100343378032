import { DatePeriodResponse } from '../models/DatePeriodResponse'
import { RateLossActLimitResponse } from '../models/Rate/RateLossActLimitResponse'
import { RatePeriodResponse } from '../models/Rate/RatePeriodResponse'
import { RatePremiumDiscountResponse } from '../models/Rate/RatePremiumDiscountResponse'
import { RateSplitPointResponse } from '../models/Rate/RateSplitPointResponse'
import { RateWeightsAndBallastsResponse } from '../models/Rate/RateWeightsAndBallastsResponse'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getRateList = async () => {
  const response: ApiResponse<RatePeriodResponse[]> = await api.get(`${Endpoints.Rates}`)
  return response.data
}

export const getRateData = async (rateId: string) => {
  const response: ApiResponse<RatePeriodResponse> = await api.get(`${Endpoints.Rates}/${rateId}/rateData`)
  return response.data
}

export const getRateLossLimits = async (ratePeriod: DatePeriodResponse) => {
  const response: ApiResponse<RateLossActLimitResponse[]> = await api.get(`${Endpoints.Rates}/rateLossLimits`, {
    params: {
      effectiveDate: ratePeriod.effectiveDate,
      expirationDate: ratePeriod.expirationDate,
    },
  })
  return response.data
}

export const getRateWeightsAndBallasts = async (ratePeriod: DatePeriodResponse) => {
  const { effectiveDate, expirationDate } = ratePeriod
  const response: ApiResponse<RateWeightsAndBallastsResponse> = await api.get(
    `${Endpoints.Rates}/rateWeightsAndBallasts`,
    { params: { effectiveDate, expirationDate } }
  )
  return response.data
}

export const getRatePremiumDiscounts = async (ratePeriod: DatePeriodResponse) => {
  const { effectiveDate, expirationDate } = ratePeriod
  const response: ApiResponse<RatePremiumDiscountResponse[]> = await api.get(
    `${Endpoints.Rates}/ratePremiumDiscounts`,
    { params: { effectiveDate, expirationDate } }
  )
  return response.data
}

export const getRateSplitPoints = async (ratePeriod: DatePeriodResponse) => {
  const { effectiveDate, expirationDate } = ratePeriod
  const response: ApiResponse<RateSplitPointResponse[]> = await api.get(`${Endpoints.Rates}/rateSplitPoints`, {
    params: { effectiveDate, expirationDate },
  })
  return response.data
}
