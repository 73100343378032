import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs, isDayjs } from 'dayjs'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAddLossCostMultiplier } from '../../cache/carrierCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import PhoenixModalHeader from './PhoenixModalHeader'

interface AddLcmModalProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  latestLcmEffectiveDate: string
  carrierGuid: string
}
interface IAddLcmForm {
  lcmFactor: string
  lcmEffectiveDate: Dayjs | string
}
const AddCarrierLossCostModal = ({
  isOpen,
  onConfirm,
  onCancel,
  latestLcmEffectiveDate,
  carrierGuid,
}: AddLcmModalProps) => {
  const formMethods = useForm<IAddLcmForm>({
    defaultValues: {
      lcmFactor: '',
      lcmEffectiveDate: '',
    },
    mode: 'all',
  })

  const { control, handleSubmit, register, reset, formState, watch } = formMethods
  const lcmFactor = watch('lcmFactor')
  const lcmEffectiveDate = watch('lcmEffectiveDate')

  const { mutateAsync, isPending, isError, isSuccess } = useAddLossCostMultiplier(carrierGuid, {
    lossCostMultiplier: lcmFactor ? Number.parseFloat(lcmFactor) : 0,
    lossCostMultiplierEffectiveDate: isDayjs(lcmEffectiveDate) ? lcmEffectiveDate.format('YYYY-MM-DD') : '',
  })
  const onSubmit = async () => {
    const result = await mutateAsync()
    if (result) {
      reset()
      onConfirm()
    }
  }
  const onCloseAndClear = () => {
    reset()
    onCancel()
  }

  useSetAlertDetails([isError], 'Changes cannot be completed. Please try again.')
  useSetAlertDetails([isSuccess], 'Loss Cost Multiplier added successfully.', AlertSeverity.SUCCESS)

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'sm'} data-testid={'add-lcm-modal'}>
      <PhoenixModalHeader title={'Add Loss Cost Multiplier'} handleClose={onCloseAndClear} isErrorColor={false} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    {...register('lcmFactor', {
                      required: 'LCM Factor is required',
                      max: { value: 9.999, message: 'LCM Factor must be less than 10' },
                      min: { value: 0.001, message: 'LCM Factor must be greater than 0' },
                      pattern: {
                        value: /^\d{1,2}(\.\d{1,3})?$/,
                        message: 'Loss Cost Multiplier must be a numeric single digit entry or x.xxx format',
                      },
                    })}
                    fullWidth
                    name="lcmFactor"
                    label="LCM Factor"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onChange={(value) => field.onChange(value)}
                    onBlur={() => {
                      const value = Number.parseFloat(field.value)
                      if (Number.isNaN(value)) {
                        field.onBlur()
                        return
                      }
                      const [integerPart, decimalPart = ''] = field.value.split('.')
                      if (decimalPart.length < 3) {
                        field.onChange(`${integerPart}.${decimalPart.padEnd(3, '0')}`)
                      }
                      field.onBlur()
                    }}
                  />
                )}
                name={'lcmFactor'}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="lcmEffectiveDate"
                  control={control}
                  rules={{
                    required: 'Effective Date is required',
                    validate: (date: Dayjs | string) => {
                      if (isDayjs(date)) {
                        if (!date.isValid()) {
                          return 'Invalid Date'
                        }
                        if (date.diff(latestLcmEffectiveDate, 'day') <= 0) {
                          return 'New Effective Date cannot overlap with a previous Loss Cost Multiplier Effective Date'
                        }
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      label="Effective Date"
                      onChange={(date) => field.onChange(date)}
                      slotProps={{
                        textField: {
                          value: field.value,
                          fullWidth: true,
                          error: fieldState.invalid,
                          helperText: fieldState.error?.message,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size={'small'} onClick={onCloseAndClear} name="cancel">
            Cancel
          </Button>
          <LoadingButton
            size={'small'}
            variant="contained"
            disabled={!formState.isValid}
            color="primary"
            type="submit"
            loading={isPending}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddCarrierLossCostModal
