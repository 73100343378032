import { PhoenixBaseCard } from 'componix'
import { useGetRateLossLimits } from '../../../cache/rateCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { DatePeriodResponse } from '../../../models/DatePeriodResponse'
import RateLossLimitsTable from './RateLossLimitsTable'

interface RateLossLimitsCardProps {
  ratePeriod: DatePeriodResponse | undefined
}

export const RateLossLimitsCard = ({ ratePeriod }: RateLossLimitsCardProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetRateLossLimits(ratePeriod as DatePeriodResponse)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Loss Limits'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <RateLossLimitsTable data={data} />
    </PhoenixBaseCard>
  )
}

export default RateLossLimitsCard
