import { BreadcrumbBar, LayoutWithSideRail } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetClaimDetails } from '../cache/claimCache'
import { useGetPolicySummaryDetails } from '../cache/policyCache'
import { useGetUnitStatDetails } from '../cache/unitStatCache'
import { ClaimDetailsContent } from '../components/ClaimDetails'
import PolicyInfoGutterCard from '../components/ClaimDetails/PolicyInfoGutterCard'
import UnitStatInfoGutterCard from '../components/ClaimDetails/UnitStatInfoGutterCard'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import RelatedCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const ClaimDetails = () => {
  const { unitStatLossId } = useParams()
  const { pageStyle } = useNavbar()

  const { data: ancestorData } = useGetAncestors(unitStatLossId as UUID, HierarchyTypes.CLAIM)

  const { data: policySummaryData, isPending: isLoadingPolicySummary } = useGetPolicySummaryDetails(
    ancestorData?.policy?.guid as UUID
  )

  const { data: unitStatData, isPending: isLoadingUnitStat } = useGetUnitStatDetails(
    ancestorData?.unitStat?.guid as string
  )

  const {
    data: claimDetailsData,
    isPending: isLoadingData,
    isError,
  } = useGetClaimDetails(unitStatLossId, ancestorData?.unitStat?.guid)

  useSetAlertDetails([isError])

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestorData?.combo?.identifier as string}`,
            to: `/combos/${ancestorData?.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestorData?.coverage?.identifier}`,
            to: `/coverages/${ancestorData?.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestorData?.policy?.identifier}`,
            to: `/policies/${ancestorData?.policy?.guid}`,
          },
          {
            label: `Unit Stat Report ${ancestorData?.unitStat?.identifier}`,
            to: `/unitstats/${ancestorData?.unitStat?.guid}`,
          },
          {
            label: `Claim ${claimDetailsData?.claimSummary?.claimNumber} - ${claimDetailsData?.claimSummary?.accidentDate}`,
            to: `/claims/${unitStatLossId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={<ClaimDetailsContent data={claimDetailsData} isLoading={isLoadingData} />}
        sideRailComponentArray={[
          <UnitStatInfoGutterCard
            key={'usigc'}
            unitStat={unitStatData}
            uuid={ancestorData?.unitStat?.guid as UUID}
            isLoading={isLoadingUnitStat}
          />,
          <PolicyInfoGutterCard
            key="pigc"
            policy={policySummaryData}
            guid={ancestorData?.policy?.guid as UUID}
            isLoading={isLoadingPolicySummary}
          />,
          <PrimaryCoverageIdentifier key={'cgh'} combo={ancestorData?.combo} coverage={ancestorData?.coverage} />,
          <RelatedCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key="rcic"
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
