import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, PhoenixBaseTable, PhoenixLink, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useState } from 'react'
import { Policy } from '../../models/Policy'
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

/* eslint react/prop-types: 0 */
const policyColumns: MRT_ColumnDef<Policy>[] = [
  {
    accessorKey: 'carrierId',
    header: 'CARRIER ID',
    size: 40,
  },
  {
    accessorKey: 'carrierName',
    header: 'CARRIER NAME',
    size: 200,
  },
  {
    accessorKey: 'policyNumber',
    header: 'POLICY NO',
    Cell: ({ cell, row }) => (
      <PhoenixLink
        value={cell.getValue<string>()}
        to={`/policies/${row.original.policyId}`}
      />
    ),
    size: 15,
  },
  {
    accessorKey: 'policyEffectiveDate',
    header: 'EFFECTIVE DATE',
    size: 15,
  },
  {
    accessorKey: 'policyExpirationDate',
    header: 'EXPIRATION DATE',
    size: 15,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    Cell: ({ cell }) => (
      <StatusBadge description={cell.getValue<string>()} />
    ),
    size: 15,
  },
  {
    accessorKey: 'statusDate',
    header: 'STATUS DATE',
    size: 15,
  },
  {
    accessorKey: 'issueDate',
    header: 'ISSUE DATE',
    size: 15,
  },
  {
    accessorKey: 'unitStatReport.dueDate',
    header: 'USR DUE DATE',
    size: 15,
    Cell: ({ row }) =>
      row.original?.unitStatReport?.nextDue != null
        ? `${row.original.unitStatReport.nextDue} - ${row.original.unitStatReport.dueDate}`
        : <DataFieldBlankValue />,
  },
]

export interface PoliciesTableProps {
  policyListData: Policy[] | undefined
  isLoading: boolean
  includeMovePolicyButton?: boolean
  allowRowSelection: boolean
  handleMovePolicyClick?: () => void
  setPoliciesToMove?: (policies: Policy[]) => void
}

export const PoliciesTable = ({
  policyListData = [],
  allowRowSelection,
  isLoading,
  includeMovePolicyButton = true,
  handleMovePolicyClick,
  setPoliciesToMove,
}: PoliciesTableProps) => {

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const handleRowSelectionChange: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
    setRowSelection((prev) => {
      return typeof updaterOrValue === "function" ? updaterOrValue(prev) : updaterOrValue;
    });
    const selectedData = Object.keys(typeof updaterOrValue === "function" ? updaterOrValue(rowSelection) : updaterOrValue)
      .map((key) => policyListData[+key]);

    if (setPoliciesToMove != undefined) {
      setPoliciesToMove(selectedData)
    }
  }

  return (
    <>
      {!!policyListData?.length && includeMovePolicyButton && <Box sx={{ margin: '12px auto -45px 18px', zIndex: 10 }}>
        <Button
          variant="contained"
          onClick={handleMovePolicyClick}
        >
          Move Policies
        </Button>
      </Box>}
      <PhoenixBaseTable
        data-testid="mrtDataTable"
        data={policyListData}
        isLoading={isLoading}
        columns={policyColumns}
        searchable
        overrides={{
          enableRowSelection: allowRowSelection,
          positionToolbarAlertBanner: 'none',
          state: allowRowSelection ? { rowSelection } : undefined,
          onRowSelectionChange: handleRowSelectionChange,
        }}
      />
    </>
  )
}

export default PoliciesTable
