import { CalendarToday, EventAvailable, EventBusy } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { BreadcrumbBar, FieldGroup, LayoutWithSideRail, PhoenixBaseCard, PhoenixBaseTable, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetEndorsementDetailsByPolicyEndorsementId } from '../cache/endorsementsCache'
import { useGetPolicySummaryDetails } from '../cache/policyCache'
import PolicyInfoGutterCard from '../components/ClaimDetails/PolicyInfoGutterCard'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import { useNavbar } from '../contexts/NavbarContext'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { generateColumns } from '../utils/table/generateColumns'

const EndorsementDetails = () => {
  const { pageStyle } = useNavbar()
  const { policyEndorsementId } = useParams()
  const { data: endorsementDetails, isPending } = useGetEndorsementDetailsByPolicyEndorsementId(policyEndorsementId)
  const { data: ancestorData, isPending: isLoadingAncestors } = useGetAncestors(
    policyEndorsementId as UUID,
    HierarchyTypes.ENDORSEMENT
  )
  const { data: policySummaryData, isPending: isLoadingPolicySummary } = useGetPolicySummaryDetails(
    ancestorData?.policy?.guid as UUID
  )
  return (
    <div style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestorData?.combo?.identifier as string}`,
            to: `/combos/${ancestorData?.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestorData?.coverage?.identifier}`,
            to: `/coverages/${ancestorData?.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestorData?.policy?.identifier}`,
            to: `/policies/${ancestorData?.policy?.guid}`,
          },
          {
            label: `Endorsement ${ancestorData?.endorsement?.identifier}`,
            to: `/endorsements/${ancestorData?.endorsement?.guid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <PhoenixBaseCard variantType="Primary" cardTitle={'Endorsement Summary'}>
              <Grid container paddingX={3} paddingTop={1}>
                <Grid item sm={3}>
                  <FieldGroup
                    isLoading={isPending}
                    label="Endorsement Number"
                    value={endorsementDetails?.endorsementNumber}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FieldGroup
                    isLoading={isPending}
                    label="Endorsement Name"
                    value={endorsementDetails?.endorsementName}
                  />
                </Grid>
                <Grid item>
                  <FieldGroup
                    isLoading={isPending}
                    label="Status"
                    value={<StatusBadge description={endorsementDetails?.extensionStatus} />}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FieldGroup
                    isLoading={isPending}
                    icon={<EventAvailable />}
                    label="Effective Date"
                    value={endorsementDetails?.effectiveDate}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FieldGroup
                    isLoading={isPending}
                    icon={<EventBusy />}
                    label="Expiration Date"
                    value={endorsementDetails?.expirationDate}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FieldGroup
                    isLoading={isPending}
                    icon={<CalendarToday />}
                    label="Issued Date"
                    value={endorsementDetails?.issuedDate}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FieldGroup
                    isLoading={isPending}
                    icon={<CalendarToday />}
                    label="Received Date"
                    value={endorsementDetails?.receivedDate}
                  />
                </Grid>
              </Grid>
            </PhoenixBaseCard>
            <PhoenixBaseCard variantType="Primary" cardTitle="Details">
              <PhoenixBaseTable
                isLoading={isPending}
                columns={generateColumns(endorsementDetails?.endorsementDetails[0] ?? {}, true)}
                data={endorsementDetails?.endorsementDetails ?? []}
                searchable
              />
            </PhoenixBaseCard>
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={'cgh'}
            combo={ancestorData?.combo}
            coverage={ancestorData?.coverage}
            isLoading={isLoadingAncestors}
          />,
          <PolicyInfoGutterCard
            key={'pigc'}
            policy={policySummaryData}
            guid={ancestorData?.policy?.guid}
            isLoading={isLoadingPolicySummary}
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default EndorsementDetails
