import { PhoenixBaseCard } from 'componix'
import { useGetRatePremiumDiscounts } from '../../../cache/rateCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { DatePeriodResponse } from '../../../models/DatePeriodResponse'
import RatePremiumDiscountTable from './RatePremiumDiscountTable'

interface RatePremiumDiscountCard {
  ratePeriod: DatePeriodResponse | undefined
}

export const RatePremiumDiscountCard = ({ ratePeriod }: RatePremiumDiscountCard) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetRatePremiumDiscounts(ratePeriod as DatePeriodResponse)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Premium Discount'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <RatePremiumDiscountTable data={data} />
    </PhoenixBaseCard>
  )
}

export default RatePremiumDiscountCard
