import { MRT_ColumnDef } from 'material-react-table'
import { RateWeight } from '../../../../models/Rate/RateWeightsAndBallastsResponse'
import { tableCellFloat } from '../../../../utils/table/tableCellFloat'

const RateWeightsTableColumns: MRT_ColumnDef<RateWeight>[] = [
  {
    accessorKey: 'lossStart',
    header: 'Loss Start',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    minSize: 50,
    size: 80,
  },
  {
    accessorKey: 'lossEnd',
    header: 'Loss End',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 0),
    minSize: 50,
    size: 80,
  },
  {
    accessorKey: 'weightFactor',
    header: 'Factor',
    Cell: ({ cell }) => tableCellFloat(cell.getValue<number>(), 2),
  },
]

export default RateWeightsTableColumns
