import { useQuery } from '@tanstack/react-query'
import { EndorsementDataResponse } from '../models/EndorsementDetails'
import { GetEndorsementDetailsByPolicyEndorsementId } from '../services/endorsementDetailsService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetEndorsementDetailsByPolicyEndorsementId = (id: string | undefined) => {
  return useQuery<EndorsementDataResponse>({
    queryKey: [CacheKeys.Endorsements, id],
    queryFn: () => GetEndorsementDetailsByPolicyEndorsementId(id as string),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!id,
  })
}
export { useGetEndorsementDetailsByPolicyEndorsementId }
