import { PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { ClassLossCostResponse } from '../../models/Class/ClassLossCost'

const columns: MRT_ColumnDef<ClassLossCostResponse>[] = [
  {
    header: 'Rate Effective Date',
    accessorKey: 'rateEffectiveDate',
    size: 10,
  },
  {
    header: 'Expo Act',
    accessorKey: 'expoAct',
    size: 10,
  },
  {
    header: 'Loss Cost',
    accessorKey: 'lossCost',
    size: 20,
  },
  {
    header: 'Expected Loss Rate (ELR)',
    accessorKey: 'expectedLossRate',
    size: 20,
  },
  {
    header: 'Ex-Medical Multiplier',
    accessorKey: 'exMedicalMultiplier',
    size: 10,
  },
]

interface LossCostProps {
  data: ClassLossCostResponse[]
  isLoading: boolean
}
const ClassLossCostTable = ({ data, isLoading }: LossCostProps) => {
  return <PhoenixBaseTable columns={columns} data={data} isLoading={isLoading} searchable />
}

export default ClassLossCostTable
