import { EndorsementDataResponse } from '../models/EndorsementDetails'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const GetEndorsementDetailsByPolicyEndorsementId = async (
  policyEndorsementId: string
): Promise<EndorsementDataResponse> => {
  const response: ApiResponse<EndorsementDataResponse> = await api.get(
    `${Endpoints.EndorsementDetails}/${policyEndorsementId}`
  )
  return response.data
}
